import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/capacitor";
import packageInfo from '../package.json';


if (environment.production) {
  enableProdMode();
}

Sentry.init({
    dsn: environment.sentryUrl,
    release: `lunchdrive-app@${packageInfo.version}`,
    // Set your dist version, such as "1"
    dist: `${packageInfo.version}`,
    integrations: [
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets:  ['localhost', 'https://lunchdrive.cz/api/', 'https://dev.lunchdrive.cz/api'],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
});
