import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable()
export class NewsService {
  private newsData = new BehaviorSubject<any>("");
  currentNewsData = this.newsData.asObservable();
  constructor() {}

  changeNewsData(data: any) {
    this.newsData.next(data);
  }
}
