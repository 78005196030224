import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { MenuPopoverComponent } from './menu-popover/menu-popover.component';
import { FooterComponent } from './footer/footer.component';
import { OrderDetailModalComponent } from './order-detail-modal/order-detail-modal.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { OrderResponseModalComponent } from './order-response-modal/order-response-modal.component';
import { LottieComponent } from 'ngx-lottie';
import { MealRatingModalComponent } from './meal-rating-modal/meal-rating-modal.component';
import {ShadowCssDirective} from "../directives/shadowCss.directive";
import {DirectivesModule} from "../directives/directives.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ShellModule,
        IonicModule,
        RouterModule,
        TranslateModule,
        ReactiveFormsModule,
        LottieComponent,
        DirectivesModule
    ],
    declarations: [
        ShowHidePasswordComponent,
        HeaderComponent,
        FooterComponent,
        AppFooterComponent,
        LoginModalComponent,
        ForgotPasswordModalComponent,
        MenuPopoverComponent,
        OrderDetailModalComponent,
        OrderResponseModalComponent,
        MealRatingModalComponent
    ],
    exports: [
        ShellModule,
        ShowHidePasswordComponent,
        HeaderComponent,
        FooterComponent,
        AppFooterComponent,
        LoginModalComponent,
        ForgotPasswordModalComponent,
        MenuPopoverComponent,
        OrderDetailModalComponent,
        OrderResponseModalComponent,
        MealRatingModalComponent
    ]
})
export class ComponentsModule {
}
