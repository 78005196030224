import { Component, Input, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { MyRbacOperations } from '../../enums/user_permissions.enum';
import { LoggedUserModel } from '../../models/logged_user.model';

@Component({
    selector: 'app-menu-popover',
    templateUrl: './menu-popover.component.html',
    styleUrls: ['./menu-popover.component.scss']
})
export class MenuPopoverComponent implements OnInit {
    readonly userPermissions = MyRbacOperations;
    @Input() userData: LoggedUserModel;

    constructor(
        public userSvc: UserService,
        public platform: Platform,
        public popoverCtrl: PopoverController
    ) {

    }

    ngOnInit() {
    }


}
