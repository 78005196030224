import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LoggedUserModel} from '../models/logged_user.model';
import {StorageService} from './storage.service';
import {JWT_KEY} from '../constants';
import {ProviderModel} from '../models/provider.model';
import {ProviderListModel} from '../models/provider_list.model';
import {AccountModel} from "../models/account.model";

@Injectable()
export class UserService {
    public tokenSubject = new BehaviorSubject<string>(null);
    public token = this.tokenSubject.asObservable();
    public userSubject = new BehaviorSubject<LoggedUserModel>(null);
    public user = this.userSubject.asObservable();
    public accountSubject = new BehaviorSubject<AccountModel>(null);
    public account = this.accountSubject.asObservable();
    public userAccountSubject = new BehaviorSubject<any>(null);
    public userAccount = this.userAccountSubject.asObservable();

    public providerListSubject = new BehaviorSubject<any>(null);
    public providerList = this.providerListSubject.asObservable();

    public providerSubject = new BehaviorSubject<any>(null);
    public provider = this.providerSubject.asObservable();

    public allowedMultiple: boolean = false;


    constructor(
        private storageSvc: StorageService
    ) {
    }

    public async getCurrentToken(): Promise<string> {
        if (!this.tokenSubject.getValue()) {
            const userDataString = await this.getUserDataFromStorage();
            if (userDataString) {
                this.setToken(JSON.parse(userDataString).token);
                this.setUser(JSON.parse(userDataString));
            }
        }
        return this.tokenSubject.getValue();
    }

    public async getUserDataFromStorage() {
        return await this.storageSvc.getItem(JWT_KEY);
    }

    public getUserAcronym(user): string {
        if (user && user.name) {
            return user.name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
        }
        return ''
    }


    public async isLogged(): Promise<boolean> {
        return !(!(await this.getCurrentToken()) || (await this.getCurrentToken()) === 'none');
    }

    public setToken(token: string): void {
        this.tokenSubject.next(token);
    }

    public setUser(user: LoggedUserModel): void {
        this.userSubject.next(user);
    }

    public setAccount(account: AccountModel): void {
        this.accountSubject.next(account);
    }

    public setProvider(provider: ProviderModel): void {
        this.providerSubject.next(provider);
    }

    public getProvider(): ProviderModel {
        return this.providerSubject.value;
    }

    public setProviderList(providers: Array<ProviderListModel>): void {
        this.providerListSubject.next(providers);
    }

    public getProviderList(): Array<ProviderListModel> {
        return this.providerListSubject.value;
    }

    public getUser(): LoggedUserModel {
        const userObj: any = this.userSubject.value;
        if (userObj) {
            if (typeof userObj === 'string') {
                return JSON.parse(userObj);
            } else {
                return userObj;
            }
        }
    }

    public getAccount(): AccountModel {
        const accountObj: any = this.accountSubject.value;
        if (accountObj) {
            if (typeof accountObj === 'string') {
                return JSON.parse(accountObj);
            } else {
                return accountObj;
            }
        }
    }

    public setUserAccount(account): void {
        this.userAccountSubject.next(account);
    }

    public getUserAccount(): any {
        return this.userAccountSubject.value;
    }

    public checkAccess(permission: string): boolean {
        const userObj: any = this.userSubject.value;
        if (userObj) {
            if (typeof userObj === 'string') {
                return JSON.parse(userObj).permissions?.includes(permission);
            } else {
                return userObj?.permissions?.includes(permission);
            }
        }

    }
}
