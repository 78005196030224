import { Component, Input, OnInit } from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import { ModalController } from '@ionic/angular';


@Component({
    selector: 'app-response-modal',
    templateUrl: './order-response-modal.component.html',
    styleUrls: ['./order-response-modal.component.scss']
})
export class OrderResponseModalComponent implements OnInit {
    @Input('orderResponse') orderResponse: { ok: boolean, msg: string };

    constructor(
        public router: Router,
        private modalCtrl: ModalController
    ) {

    }

    async ngOnInit() {
        console.log('orderResponse', this.orderResponse);

    }

    async ionViewWillEnter() {

    }


    async close() {
        await this.modalCtrl.dismiss();
    }

    async redirect(url: string) {
        await this.modalCtrl.dismiss();
        let navigationExtras: NavigationExtras = {
            state: {
                closest: true
            }
        };
        await this.router.navigate([url],navigationExtras);
    }

}
