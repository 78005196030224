// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-image-shell.user-avatar {
  --image-shell-loading-background: rgba(var(--ion-color-light-rgb), 0.15);
  --image-shell-border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/side-menu/styles/side-menu.shell.scss"],"names":[],"mappings":"AAAA;EACE,wEAAA;EACA,gCAAA;AACF","sourcesContent":["app-image-shell.user-avatar {\n  --image-shell-loading-background: rgba(var(--ion-color-light-rgb), 0.15);\n  --image-shell-border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
