export enum CaseTypeEnum {
    NONE_STRING = '0', //žádné
    DISPOSABLE_STRING = '1', //jednorázové
    REFUNDABLE_STRING = '2', //vratné
    OWN_STRING = '3', //vlastní
    NONE = 0, //žádné
    DISPOSABLE = 1, //jednorázové
    REFUNDABLE = 2, //vratné
    OWN = 3 //vlastní
}
