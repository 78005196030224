import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ProviderLunchListModel} from "../models/provider_lunch_list.model";
import {HttpService} from "./http.service";

@Injectable()
export class PaymentService {
    constructor(
        private httpSvc: HttpService,
    ) {
    }

    public async getStripeCustomer(): Promise<any> {
        return await this.httpSvc.send<any>('GET', `/stripe/customer`);
    }

    public async getStripeEphemeralKey(): Promise<any> {
        return await this.httpSvc.send<any>('GET', `/stripe/ephemeralKey`);
    }

    public async getStripePaymentIntent(provider_id, amount): Promise<any> {
        return await this.httpSvc.send<any>('POST', `/stripe/paymentIntent?provider_id=${provider_id}&amount=${amount}`);
    }
}
