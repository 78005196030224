import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {LunchMarketService} from "../../services/lunch-market.service";
import {MarketableLunchModel} from "../../models/marketable_lunch.model";
import {MarketLunchModel} from "../../models/market_lunch.model";
import {Subscription} from "rxjs";
import {HelperService} from "../../services/helper.service";

@Component({
    selector: 'app-mobile-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
    public currentPage: string;
    public marketableLunches: Array<MarketableLunchModel>;
    public marketAvailableLunches: Array<MarketLunchModel>;

    public isLunchMarketActive: boolean = false

    private marketableSubscription: Subscription;
    private marketAvailableSubscription: Subscription;

    constructor(
        public router: Router,
        public platform: Platform,
        private lunchMarketSvc: LunchMarketService,
        private helperSvc: HelperService
    ) {
        this.currentPage = this.router.url;
        this.marketableSubscription = this.lunchMarketSvc.marketable.subscribe(async (resp: Array<MarketableLunchModel>) => {
            if (resp) {
                this.marketableLunches = this.helperSvc.groupByKey(resp, 'lunch_date');
                if (this.marketableLunches && Object.keys(this.marketableLunches).length) {
                    this.isLunchMarketActive = true
                }
            }
        });
        this.marketAvailableSubscription = this.lunchMarketSvc.marketAvailable.subscribe(async (resp: Array<MarketLunchModel>) => {
            if (resp) {
                this.marketAvailableLunches = resp;
                if (this.marketAvailableLunches && this.marketAvailableLunches.length) {
                    this.isLunchMarketActive = true
                }
            }
        });
    }

    async navTo(page: string): Promise<void> {
        await this.router.navigate([page]);
    }

}
