import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot} from '@angular/router';
import {UserService} from './user.service';
import {HelperService} from "./helper.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {


    constructor(private router: Router, private userSvc: UserService, private helperSvc: HelperService) {
    }

    async canActivate(route: ActivatedRouteSnapshot) {
        const roles = route.data.roles as Array<string>;
        if (!await this.userSvc.isLogged()) {
            await this.router.navigate(['home']);
            return false;
        }
        if (roles && !roles.includes(this.userSvc.getUser().user.role)) {
            await this.router.navigate(['']);
            return false;
        }
        /*
        if (roles && roles.includes(this.userSvc.userSubject.getValue().accountType)) {
            return true;
        } else {
            this.router.navigate(['/page-not-found']);
            return false;
        }*/
    }
}
