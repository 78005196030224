// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-footer {
  background: var(--ion-color-background) !important;
  border-top: 1px solid var(--ion-color-lightest);
  padding: calc(var(--app-broad-margin) * 2) 0 calc(var(--app-broad-margin) * 3);
}
:host ion-footer:before {
  display: none;
}
:host ion-footer ion-title {
  font-weight: 400;
  color: var(--ion-color-medium);
  font-size: 30px;
}
:host ion-footer ion-title span {
  color: var(--ion-color-primary);
}
:host ion-footer ion-row ion-col {
  padding: 0;
}
:host ion-footer ion-row ion-col ul {
  margin: 0;
  padding: 0;
  padding-top: 5px;
}
:host ion-footer ion-row ion-col ul li {
  list-style: none;
  transition: all 0.3s ease;
}
:host ion-footer ion-row ion-col ul li:hover {
  cursor: pointer;
  opacity: 0.3;
}
:host ion-footer ion-row ion-col ul li + li {
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AACC;EACC,kDAAA;EACA,+CAAA;EACA,8EAAA;AAAF;AACE;EACC,aAAA;AACH;AACE;EACC,gBAAA;EACA,8BAAA;EACA,eAAA;AACH;AACG;EACC,+BAAA;AACJ;AAGG;EACC,UAAA;AADJ;AAEI;EACC,SAAA;EACA,UAAA;EACA,gBAAA;AAAL;AACK;EACC,gBAAA;EACA,yBAAA;AACN;AAAM;EACC,eAAA;EACA,YAAA;AAEP;AAAM;EACC,eAAA;AAEP","sourcesContent":[":host {\n\tion-footer {\n\t\tbackground: var(--ion-color-background) !important;\n\t\tborder-top: 1px solid var(--ion-color-lightest);\n\t\tpadding: calc(var(--app-broad-margin) * 2) 0 calc(var(--app-broad-margin) * 3);\n\t\t&:before{\n\t\t\tdisplay: none;\n\t\t}\n\t\tion-title {\n\t\t\tfont-weight: 400;\n\t\t\tcolor: var(--ion-color-medium);\n\t\t\tfont-size: 30px;\n\n\t\t\tspan {\n\t\t\t\tcolor: var(--ion-color-primary);\n\t\t\t}\n\t\t}\n\t\tion-row{\n\t\t\tion-col{\n\t\t\t\tpadding: 0;\n\t\t\t\tul{\n\t\t\t\t\tmargin: 0;\n\t\t\t\t\tpadding: 0;\n\t\t\t\t\tpadding-top: 5px;\n\t\t\t\t\tli{\n\t\t\t\t\t\tlist-style: none;\n\t\t\t\t\t\ttransition:  all .3s ease;\n\t\t\t\t\t\t&:hover{\n\t\t\t\t\t\t\tcursor: pointer;\n\t\t\t\t\t\t\topacity: .3;\n\t\t\t\t\t\t}\n\t\t\t\t\t\t+li{\n\t\t\t\t\t\t\tmargin-top: 8px;\n\t\t\t\t\t\t}\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
