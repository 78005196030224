import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { HistoryOrderModel } from '../../models/history_order.model';
import { ApiService } from '../../services/api.service';
import * as Sentry from "@sentry/capacitor";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'meal-rating-modal',
    templateUrl: './meal-rating-modal.component.html',
    styleUrls: ['./meal-rating-modal.component.scss']
})
export class MealRatingModalComponent implements OnInit {
    @Input() order: HistoryOrderModel;

    form: FormGroup;

    constructor(
        public router: Router,
        public menu: MenuController,
        private modalCtrl: ModalController,
        private apiSvc: ApiService,
        private userSvc: UserService
    ) {
        this.form = new FormGroup({
            order_id: new FormControl(''),
            text: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
        this.form.controls['order_id'].setValue(this.order.id);
    }

    async sendComment(): Promise<void> {
        try {
            const commentResp = await this.apiSvc.addMealComment(this.form.value);
            console.log('commentResp', commentResp);
            if (commentResp) {
                this.order.comment = this.form.value.text;
                await this.close(this.order);
            }
        } catch (e) {
            Sentry.withScope((scope) => {
                scope.setTag("userId", this.userSvc.getUser().user.id)
                scope.setTag("userEmail", this.userSvc.getUser().user.email)
                Sentry.setExtra('Error Message', e)
                Sentry.captureException('SEND COMMENT ERR: ' + JSON.stringify(e));
            })
            console.log('login error: ', e);
        }
    }

    async close(data = null) {
        await this.modalCtrl.dismiss(data);
    }
}
