import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import {OrderGuardService} from "./services/order-guard.service";

const routes: Routes = [
    /*
    {
        path: 'login',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: 'news',
        loadChildren: () =>
            import('./pages/news/news.module').then((m) => m.NewsPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'news/add',
        loadChildren: () =>
            import('./pages/news/add-news/add-news.module').then(
                (m) => m.AddNewsPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'terminal',
        loadChildren: () =>
            import('./pages/terminal/terminal.module').then((m) => m.TerminalPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'dispensing',
        loadChildren: () =>
            import('./pages/dispensing/dispensing.module').then(
                (m) => m.DispensingPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'payments',
        loadChildren: () =>
            import('./pages/payments/payments.module').then((m) => m.PaymentsPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'payment-card',
        loadChildren: () =>
            import('./pages/payment-card/payment-card.module').then((m) => m.PaymentCardPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'packaging',
        loadChildren: () =>
            import('./pages/packaging/packaging.module').then((m) => m.PackagingPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'packaging/info',
        loadChildren: () =>
            import('./pages/packaging/packaging-info/packaging-info.module').then(
                (m) => m.PackagingInfoPageModule
            ),
        canActivate: [AuthGuardService]
    },

    {
        path: 'provider/overview/notification',
        loadChildren: () =>
            import(
                './pages/provider-overview/notification/provider-overview-notification.module'
                ).then((m) => m.ProviderOverviewNotificationPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/offer',
        loadChildren: () =>
            import('./pages/provider-offer/provider-offer.module').then(
                (m) => m.ProviderOfferPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/orders',
        loadChildren: () =>
            import('./pages/provider-orders/provider-orders.module').then(
                (m) => m.ProviderOrdersPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/orders/detail',
        loadChildren: () =>
            import(
                './pages/provider-orders/provider-orders-detail/provider-orders-detail.module'
                ).then((m) => m.ProviderOrdersDetailPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/orders/edit',
        loadChildren: () =>
            import(
                './pages/provider-orders/provider-orders-edit/provider-orders-edit.module'
                ).then((m) => m.ProviderOrdersEditPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/orders/multicreate',
        loadChildren: () =>
            import(
                './pages/provider-orders/provider-orders-multicreate/provider-orders-multicreate.module'
                ).then((m) => m.ProviderOrdersMulticreatePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/add-lunch',
        loadChildren: () =>
            import('./pages/provider-add-lunch/provider-add-lunch.module').then(
                (m) => m.ProviderAddLunchPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/clients',
        loadChildren: () =>
            import('./pages/provider-clients/provider-clients.module').then(
                (m) => m.ProviderClientsPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },

    {
        path: 'provider/client/users',
        loadChildren: () =>
            import(
                './pages/provider-clients/provider-client-users/provider-client-users.module'
                ).then((m) => m.ProviderClientUsersPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/add-client',
        loadChildren: () =>
            import(
                './pages/provider-clients/provider-add-client/provider-add-client.module'
                ).then((m) => m.ProviderAddClientPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/billing',
        loadChildren: () =>
            import('./pages/provider-billing/provider-billing.module').then(
                (m) => m.ProviderBillingPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'provider/rating',
        loadChildren: () =>
            import('./pages/provider-rating/provider-rating.module').then(
                (m) => m.ProviderRatingPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    {
        path: 'client/order/payment',
        loadChildren: () =>
            import(
                './pages/client-order/client-order-payment/client-order-payment.module'
                ).then((m) => m.ClientOrderPaymentPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm'] }
    },
    {
        path: 'qr',
        loadChildren: () =>
            import('./pages/QR-code/QR-code.module').then((m) => m.QrCodePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm'] }
    },
    // other
    {
        path: 'more',
        loadChildren: () =>
            import('./pages/more/more.module').then((m) => m.MorePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },

    {
        path: 'site/dodavatel/:providerId',
        loadChildren: () =>
            import('./pages/provider/provider.module').then((m) => m.ProviderPageModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'user/dashboard',
        loadChildren: () =>
            import('./pages/user/dashboard/dashboard.module').then(
                (m) => m.DashboardPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'report/provider',
        loadChildren: () =>
            import('./pages/report-provider/report.module').then((m) => m.ReportPageModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'orders',
        loadChildren: () =>
            import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'auth',
        redirectTo: 'auth/login',
        pathMatch: 'full'
    },
    {
        path: 'auth/login',
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule)
    },
    {
        path: 'auth/forgot-password',
        loadChildren: () =>
            import('./pages/forgot-password/forgot-password.module').then(
                (m) => m.ForgotPasswordPageModule
            )
    },

    {
        path: 'firebase',
        redirectTo: 'firebase/auth/sign-in',
        pathMatch: 'full'
    },
     */
    {
        path: 'orders',
        loadChildren: () =>
            import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'account/update/:id',
        loadChildren: () =>
            import('./pages/account/update/account-update.module').then((m) => m.AccountUpdatePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },

    {
        path: 'home',
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomePageModule)
    },
    {
        path: 'page-not-found',
        loadChildren: () =>
            import('./pages/page-not-found/page-not-found.module').then(
                (m) => m.PageNotFoundModule
            )
    },
    {
        path: 'lunch-market',
        loadChildren: () =>
            import('./pages/lunch-market/lunch-market.module').then((m) => m.LunchMarketPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'profile/password-change',
        loadChildren: () =>
            import('./pages/profile//password-change/password-change.module').then((m) => m.PasswordChangePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: 'payment-card',
        loadChildren: () =>
            import('./pages/payment-card/payment-card.module').then((m) => m.PaymentCardPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    {
        path: '',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then(
                (m) => m.DashboardPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm', 'Provider'] }
    },
    // provider routers
    {
        path: 'provider/overview',
        loadChildren: () =>
            import('./pages/provider-overview/provider-overview.module').then(
                (m) => m.ProviderOverviewPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Provider'] }
    },
    // client routers
    {
        path: 'client/order',
        loadChildren: () =>
            import('./pages/client-order/client-order.module').then(
                (m) => m.ClientOrderPageModule
            ),
        canActivate: [AuthGuardService],
        canDeactivate: [OrderGuardService],
        data: { roles: ['Firm'] }
    },
    {
        path: 'client/order/payment',
        loadChildren: () =>
            import(
                './pages/client-order/client-order-payment/client-order-payment.module'
                ).then((m) => m.ClientOrderPaymentPageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm'] }
    },
    {
        path: 'client/history',
        loadChildren: () =>
            import('./pages/client-history/client-history.module').then(
                (m) => m.ClientHistoryPageModule
            ),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm'] }
    },
    {
        path: 'client/qr',
        loadChildren: () =>
            import('./pages/qr-code/qr-code.module').then((m) => m.QrCodePageModule),
        canActivate: [AuthGuardService],
        data: { roles: ['Firm'] }
    },
    {
        path: '**',
        redirectTo: 'page-not-found'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
