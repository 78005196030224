export enum UserRoleEnum {
    FIRM = 'Firm',
    PROVIDER = 'Provider',
    USER = 'User',
    ADMIN = 'admin',
    PAYMENT_USER = 'rUserWithPayment',
    ACCOUNTANT = 'rAccountant',
    COOK_EDITOR = 'rCookEditor',
    COOK_VIEWER = 'rCookViewer',
    DRIVER = 'rDriver',
    DRIVER_BASIC = 'rDriverBasic',
    MANAGER = 'rManager',
    TERMINAL_OPERATOR = 'rTerminalOperator',
    CLIENT_ADMIN = 'rClientAdmin',
    ORDER_ADMIN = 'rOrderAdmin',
    PAYMENT_ROLE = 'rPaymentRole'
}
