import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {HelperService} from "./helper.service";
import {ProviderAddressModel} from "../models/provider_address.model";
import {DeliveryRateModel} from "../models/delivery_rate.model";
import {ProviderListModel} from "../models/provider_list.model";
import {Subscription} from "rxjs";
import {OrderService} from "./order.service";
import {Haptics, NotificationType} from "@capacitor/haptics";

@Injectable({
    providedIn: 'root'
})
export class OrderGuardService {
    private redirectUrl: string

    constructor(
        private alertCtrl: AlertController,
        private translateSvc: TranslateService,
        private router: Router,
        private helperSvc: HelperService,
        private orderSvc: OrderService
    ) {
        this.router.events.subscribe((event: any) => {
            this.redirectUrl = event.url;
        });
    }

    async canDeactivate() {
        if (JSON.stringify(this.orderSvc.orderSubject.value) !== this.orderSvc.orderRawSubject.value) {
            const alert = await this.alertCtrl.create({
                header: this.translateSvc.instant('page.client.order.text.page_leave'),
                message: this.translateSvc.instant('page.client.order.text.page_leave_message'),
                buttons: [
                    {
                        text: this.translateSvc.instant('alert.button.cancel'),
                        handler: async () => {
                            await alert.dismiss()
                        }
                    },
                    {
                        text: this.translateSvc.instant('alert.button.ok'),
                        handler: () => {
                            this.helperSvc.orderComplete = true;
                            this.helperSvc.orderNewMeals.next(0);
                            this.orderSvc.orderSubject.next({});
                            this.orderSvc.orderRawSubject.next('{}');
                            this.router.navigate([this.redirectUrl])
                        }
                    }
                ]
            });
            await alert.present();
            await Haptics.notification({type: NotificationType.Warning})
            return false;
        }
    }
}
