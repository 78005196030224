import {Injectable, Optional} from '@angular/core';
import * as dayjs from 'dayjs';
import {TranslateService} from '@ngx-translate/core';
import {TranslationsService} from './translations.service';
import {App} from "@capacitor/app";
import {AlertController, IonRouterOutlet, Platform} from "@ionic/angular";
import {ProviderListModel} from "../models/provider_list.model";
import {ProviderModel} from "../models/provider.model";
import {UserRoleEnum} from "../enums/user_role.enum";
import {UserService} from "./user.service";
import {ApiService} from "./api.service";
import {BehaviorSubject} from "rxjs";
import * as Sentry from "@sentry/capacitor";
import {LoggedUserDataModel} from "../models/logged_user.model";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public today = dayjs().format('YYYY-MM-DD');
    tap = 0;
    public userAcronym: string;
    public user: any;

    public orderNewMeals: any = new BehaviorSubject<number>(0);
    public allowedAccessRoles: Array<UserRoleEnum> = [UserRoleEnum.USER, UserRoleEnum.PAYMENT_USER, UserRoleEnum.FIRM]

    //orderNewMeals: number = 0;
    orderComplete: boolean = false;

    constructor(
        private translateSvc: TranslateService,
        private translationsSvc: TranslationsService,
        private alertCtrl: AlertController,
        private platform: Platform,
        private userSvc: UserService,
        private apiSvc: ApiService,
        private storageSvc: StorageService,
        @Optional() private routerOutlet?: IonRouterOutlet
    ) {
    }

    getFormattedDay(date: string, shortLong: boolean = false) {
        return date === this.today ? this.translateSvc.instant('text.today') : this.getDayName(date, this.translationsSvc.getCurrentLocale(), shortLong) + ' ' + dayjs(date).format('DD. MM. YYYY');
    }

    getDayName(dateStr, locale, shortLong) {
        var date = new Date(dateStr);
        if (window.innerWidth > 360) {
            return date.toLocaleDateString(locale, {weekday: 'long'});
        }
        return date.toLocaleDateString(locale, {weekday: shortLong ? 'short' : 'long'});
    }

    getDayOrderLength(orderObject): number {
        return Object.keys(orderObject).length;
    }

    sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
                if (obj[key] === undefined) {
                    return hash;
                }
                return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
            }, {})
    }

    exitAppOnDoubleTap() {
        if (this.platform.is('android')) {
            this.platform.backButton.subscribeWithPriority(10, async () => {
                if (!this.routerOutlet?.canGoBack()) {
                    this.tap++;
                    if (this.tap == 2) await this.presentExitAppAlert();
                }
            });
        }
    }

    async presentExitAppAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translateSvc.instant('text.app_exit_title'),
            message: this.translateSvc.instant('text.app_exit_message'),
            buttons: [
                {
                    text: this.translateSvc.instant('text.app_exit_cancel'),
                    handler: () => {
                        this.tap = 0
                    }
                },
                {
                    text: this.translateSvc.instant('text.app_exit_submit'),
                    handler: () => {
                        this.tap = 0
                        App.exitApp();
                    }
                }
            ]
        });
        await alert.present();
    }

    async refreshUser(event: any) {
        try {
            const userData: LoggedUserDataModel = await this.apiSvc.getUserData(this.userSvc.getUser().user.id.toString());
            const user = this.userSvc.getUser();
            if (userData) {
                user.permissions = userData.permissions;
                user.accountType = userData.accountType;
                for (const key in user.user) {
                    if (user.user.hasOwnProperty(key)) {
                        user.user[key] = userData[key];
                    }
                }
                this.userSvc.setUser(user);
            }
            if (event)
                event.target.complete();
            return user
        } catch (e) {
            console.warn("REFRESH USER ERR,", e)
            Sentry.withScope((scope) => {
                scope.setTag("userId", this.userSvc.getUser().user.id)
                scope.setTag("userEmail", this.userSvc.getUser().user.email)
                Sentry.setExtra('Error Message', e)
                Sentry.captureException('REFRESH USER ERR: '+ JSON.stringify(e));
            })
        }

    }


    public async initUserAndProviders(initialProvider: ProviderModel = undefined) {
        if (this.userSvc.getUser()) {
            try {
                this.user = this.userSvc.getUser().user;
                this.userAcronym = this.userSvc.getUserAcronym(this.user);
                if (this.userHasAccess()) {
                    const providers: ProviderListModel[] = await this.apiSvc.getProviderList();
                    if (providers && providers.length) {
                        this.userSvc.setProviderList(providers);
                        const provider: ProviderModel = await this.apiSvc.getProviderInfo(initialProvider ? initialProvider.id : providers[0].id);
                        provider.no_debt = providers[providers.findIndex(pr => pr.id === Number(provider.id))].no_debt;
                        this.userSvc.setProvider(provider);
                        if (this.user.role === UserRoleEnum.USER || this.user.role === UserRoleEnum.PAYMENT_USER) {
                            this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches
                        }
                        if (this.user.role === UserRoleEnum.FIRM) {
                            if (!providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders) {
                                if (providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches) {
                                    this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].more_lunches
                                } else {
                                    this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders
                                }

                            } else {
                                this.userSvc.allowedMultiple = providers[providers.findIndex(l => l.id === Number(provider.id))].bulk_orders
                            }
                        }

                    }
                }
            } catch (e) {
                Sentry.withScope((scope) => {
                    scope.setTag("userId", this.userSvc.getUser().user.id)
                    scope.setTag("userEmail", this.userSvc.getUser().user.email)
                    Sentry.setExtra('Error Message', e)
                    Sentry.captureException('INIT USERS AND PROVIDERS ERR: ' + JSON.stringify(e));
                })
            }
        }
    }

    userHasAccess(): boolean {
        if (this.userSvc.getUser()) {
            if (this.userSvc.getUser().user && this.userSvc.getUser().user.role) {
                return this.allowedAccessRoles.includes(this.userSvc.getUser().user.role as UserRoleEnum)
            }
            return false
        }
        return false
    }
}
