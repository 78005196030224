// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
:host .content-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/shell/aspect-ratio/aspect-ratio.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AACJ","sourcesContent":[":host {\n  display: block;\n  overflow: hidden;\n  position: relative;\n  width: 100%;\n\n  .content-wrapper {\n    position: absolute;\n    top: 0px;\n    bottom: 0px;\n    left: 0px;\n    right: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
