// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    mode: 'Development',
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyCNQfKBxL3nYPOGpwrRricqUs4r-9H7wTE",
        authDomain: "lunchrive-trueapps.firebaseapp.com",
        projectId: "lunchrive-trueapps",
        storageBucket: "lunchrive-trueapps.firebasestorage.app",
        messagingSenderId: "452666712824",
        appId: "1:452666712824:web:3d9eb300c157b99dde110d",
        measurementId: "G-6LC8WLV9BG"
    },
    appShellConfig: {
        debug: false,
        networkDelay: 500
    },
    stripeApiKey: 'pk_test_51HbVmLJUNMBDs1naqtqH2pYKhTo05IyKj2UbGmb5x0CP88puhBwzGfbmoUISbDGs0Lb4OeBfHD7tGwk2P848kkCW00KMohA4Sa',
    stripeAccountId: 'acct_1Hf3keKQaq03voV7',
    sentryUrl: 'https://18c87ad468be21d9d08708b16a8a3dd5@o152749.ingest.us.sentry.io/4508359007404032',
    apiUrl: 'https://lunchdrive.cz/api',
    server: 'https://lunchdrive.cz'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
