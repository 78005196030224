import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {OrderMealModel} from '../models/order_meal.model';
import {StorageService} from './storage.service';
import {DeliveryOptionsModel} from '../models/delivery_options.model';
import {DeliveryTypeEnum} from '../enums/delivery_type.enum';
import {ProviderListModel} from '../models/provider_list.model';
import {DeliveryRateModel} from '../models/delivery_rate.model';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ProviderModel} from '../models/provider.model';
import {HistoryOrderModel} from "../models/history_order.model";
import {HelperService} from "./helper.service";
import {CaseTypeEnum} from "../enums/case_type.enum";
import {UserService} from "./user.service";
import {UserRoleEnum} from "../enums/user_role.enum";
import {Haptics, ImpactStyle} from "@capacitor/haptics";
import * as Sentry from "@sentry/capacitor";

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    public orderSubject: any = new BehaviorSubject<any>({});
    public order: any = this.orderSubject.asObservable();

    public orderRawSubject: any = new BehaviorSubject<string>('{}');
    public orderRaw: any = this.orderRawSubject.asObservable();

    public orderChanged: boolean = false;
    public orderLength: number = 0;
    private orderSubscription: Subscription;
    public allMealCanUnOrder: boolean = false;

    public addedMealsToOrder = []
    public addedBoxesToOrder: number = 0
    public userBalanceExt: number = 0

    constructor(
        private storageSvc: StorageService,
        private alertCtrl: AlertController,
        private helperSvc: HelperService,
        private translateSvc: TranslateService,
        private userSvc: UserService
    ) {
        this.orderSubscription = this.order.subscribe(async (resp) => {
            this.orderLength = this.getOrdersLength(resp);
        });
    }

    public async orderMeal(date: string, meal: any, provider: ProviderListModel, allowedMultiple: boolean, historyMeal: HistoryOrderModel = null, menu: Array<OrderMealModel[]> = null): Promise<void> {
        let orders = {};
        if (historyMeal) {
            meal = {
                id: historyMeal.lunchId,
                name: historyMeal.name,
                note: historyMeal.note,
                branch: historyMeal.address_id ? historyMeal.address_id : 0,
                delivery: historyMeal.address_id ? 'takeaway' : 'delivery',
                deliveryPrice: historyMeal.address_id ? 'takeaway' : 'delivery',
                userData: {
                    price: historyMeal.price,
                    boxes: (historyMeal.onlyOne === '1') ? (historyMeal.box !== CaseTypeEnum.NONE_STRING) ? 1 : !provider.optional_case ? 1 : 0 : provider.optional_case ? 1 : 0,
                    //boxes: (historyMeal.box === CaseTypeEnum.DISPOSABLE_STRING && priceBox > 0) ? 1 : (provider.optional_case && Number(historyMeal.box) > 0) ? 1 : (!provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE_STRING) ? 1 : 0,
                    //boxes: (historyMeal.box === CaseTypeEnum.DISPOSABLE_STRING && priceBox > 0) ? 1 :  0,
                    boxPrice: historyMeal.onlyOne === '1' ? (!provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE_STRING) ? provider.box_oneuse_price : Number(historyMeal.case_price) : 0,
                    optionalCase: menu[historyMeal.lunchDate].find(m => m.id === historyMeal.lunchId).userData.optionalCase,
                    ordered: '1'
                }
            };
        }
        if (this.orderSubject.value) { //check if order already exists
            orders = this.orderSubject.value;
            if (orders[date]) { //check if order includes selected meal date
                if (allowedMultiple) {
                    if (orders[date].orders[meal.id]) { //check if order date includes selected meals
                        orders[date].orders[meal.id].qty += 1;
                        //orders[date].orders[meal.id].box += historyMeal ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE_STRING ? 1 : 0;
                        orders[date].orders[meal.id].box += historyMeal ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE_STRING ? 1 : 0;
                        orders[date].orders[meal.id].boxPrice = orders[date].orders[meal.id].boxPrice ? orders[date].orders[meal.id].boxPrice : meal.userData.boxPrice
                        this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value + 1);
                    } else {
                        const orderObj = this.createDateOrderObj(date, meal, provider);
                        orders[date].orders = Object.assign(orders[date].orders, orderObj[date].orders);
                    }
                } else {
                    if (orders[date].orders[meal.id]) {
                        delete orders[date].orders[meal.id]
                        this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value - 1);
                    } else {
                        if (meal.onlyOne === '1') {
                            for (let mealId of Object.keys(orders[date].orders)) {
                                if (menu[date].find(m => m.id === mealId).onlyOne === '1') {
                                    delete orders[date].orders[mealId]
                                    this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value - 1);
                                }
                            }
                        }
                        orders[date].note = this.userSvc.getUser().user.meal_note;
                        orders[date].orders[meal.id] = {
                            id: Number(meal.id),
                            qty: 1,
                            box: meal.userData.ordered ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE_STRING ? 1 : 0,
                            name: meal.name,
                            price: meal.userData.price,
                            boxPrice: meal.userData.ordered ? meal.userData.boxPrice : provider.box === CaseTypeEnum.DISPOSABLE_STRING ? provider.box_oneuse_price : 0,
                            optionalCase: Number(meal.userData.optionalCase)
                        }
                        this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value + 1);
                    }
                }
            } else {
                const orderObj = this.createDateOrderObj(date, meal, provider);
                orders = Object.assign(orders, orderObj);
            }

            await this.storageSvc.setItem('userOrder', JSON.stringify(await this.placeOrderUnderProvider(provider, orders)));
            this.orderSubject.next(orders);
        } else {
            const orderObj = this.createDateOrderObj(date, meal, provider);

            await this.storageSvc.setItem('userOrder', JSON.stringify(await this.placeOrderUnderProvider(provider, orderObj)));
            this.orderSubject.next(orderObj);

        }
    }

    async placeOrderUnderProvider(provider: ProviderModel | ProviderListModel, orders) {
        const order = await this.storageSvc.getItem('userOrder');
        let providerOrder = {};
        if (order) {
            providerOrder = JSON.parse(order);
            if (providerOrder[provider.id]) {
                providerOrder[provider.id] = orders;
            } else {
                const obj = {
                    [provider.id]: orders
                };
                providerOrder = Object.assign(providerOrder, obj);
            }
        } else {
            const obj = {
                [provider.id]: orders
            };
            providerOrder = Object.assign(providerOrder, obj);

        }
        return providerOrder;
    }

    private createDateOrderObj(date: string, meal: OrderMealModel, provider: ProviderListModel = null) {
        const order = {
            [date]: {
                orders: {
                    [meal.id]: {
                        id: Number(meal.id),
                        qty: 1,
                        box: meal.userData.ordered ? meal.userData.boxes : meal.userData.optionalCase === '1' ? 0 : meal.userData.optionalCase === '0' && provider.box === CaseTypeEnum.DISPOSABLE_STRING ? 1 : 0,
                        name: meal.name,
                        price: meal.userData.price,
                        boxPrice: meal.userData.ordered ? meal.userData.boxPrice : provider.box === CaseTypeEnum.DISPOSABLE_STRING ? provider.box_oneuse_price : 0,
                        optionalCase: Number(meal.userData.optionalCase)
                    }
                },
                note: meal.note ? meal.note : this.userSvc.getUser().user.meal_note ? this.userSvc.getUser().user.meal_note : '',
                branch: meal.branch ? meal.branch : 0,
                delivery: {
                    selected: meal.delivery ? meal.delivery : '',
                    price: 0
                }
            }
        }
        this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value + 1);
        return order;
    }

    public getOrdersLength(order): number {
        if (order) {
            let length = 0;
            for (const dayKey of Object.keys(order)) {
                const ordersKeys = Object.keys(order[dayKey].orders);
                for (const key of ordersKeys) {
                    if (order[dayKey].orders[key].qty > 0)
                        length += order[dayKey].orders[key].qty;
                }
            }
            return length;
        }
    }

    public getOrder(): any {
        return this.orderSubject.value;
    }

    public async clearOrder(): Promise<void> {
        this.orderSubject.next({});
        this.orderRawSubject.next('{}');
        await this.storageSvc.setItem('userOrder', JSON.stringify({}));

    }

    public getDailyOrderTotal(day: string): number {
        const order = this.getOrder();
        let dailyOrderPriceTotal: number = 0;
        if (order) {
            for (const dayOrder of Object.keys(order[day].orders)) {
                const mealPrice = order[day].orders[dayOrder].qty * Number(order[day].orders[dayOrder].price)
                const boxPrice = order[day].orders[dayOrder].boxPrice * order[day].orders[dayOrder].box
                dailyOrderPriceTotal = dailyOrderPriceTotal + mealPrice + boxPrice;
            }
        }
        return dailyOrderPriceTotal;
    }

    public getHistoryDailyOrderTotal(day: string, history: any): number {
        const order = history;
        let dailyOrderPriceTotal: number = 0;
        if (order) {
            for (const dayOrder in Object.keys(order[day])) {
                dailyOrderPriceTotal = dailyOrderPriceTotal + Number(order[day][dayOrder].price);
            }

        }
        return dailyOrderPriceTotal;
    }

    public getOrderTotal(casePrice: number = 0, menu: any = null): number {
        try {
            const order = this.getOrder();
            let orderPriceTotal: number = 0;
            if (order) {
                for (const keyDay of Object.keys(order)) {
                    for (const keyOrder of Object.keys(order[keyDay].orders)) {
                        let boxesPrice = order[keyDay].orders[keyOrder].box * casePrice;
                        if (menu && menu[keyDay]) {
                            const meal = menu[keyDay].find(m => m.id === keyOrder)
                            if (meal && meal.onlyOne === '0') {
                                boxesPrice = 0
                            }
                        }
                        orderPriceTotal += (order[keyDay].orders[keyOrder].qty * Number(order[keyDay].orders[keyOrder].price)) + boxesPrice;
                    }
                }
            }
            return orderPriceTotal;
        } catch (e) {
            Sentry.withScope((scope) => {
                scope.setTag("userId", this.userSvc.getUser().user.id)
                scope.setTag("userEmail", this.userSvc.getUser().user.email)
                Sentry.setExtra('Error Message', e)
                Sentry.captureException('GET ORDER TOTAL ERR: ' + JSON.stringify(e));
            })
        }

    }

    public async checkOrder(provider: ProviderModel, menu: Array<OrderMealModel[]> = []): Promise<void> {
        try {
            const order = await this.storageSvc.getItem('userOrder');
            if (order) {
                if (JSON.parse(order)[provider.id]) {
                    this.orderSubject.next(JSON.parse(order)[provider.id]);

                    //helper to check if history meal in week are  all unOrdered
                    const providerOrder = JSON.parse(order)[provider.id];
                    let canOrderArray = [];
                    for (const orderDay of Object.keys(providerOrder)) {
                        for (const dayMeal of Object.keys(providerOrder[orderDay].orders)) {
                            if (menu && menu[orderDay]) {
                                const menuMeal = menu[orderDay].find(m => m.id === dayMeal)
                                if (menuMeal && menuMeal.userData.canOrder && menuMeal.userData.canUnOrder)
                                    canOrderArray.push(dayMeal)
                            }
                        }
                    }
                    this.allMealCanUnOrder = !canOrderArray.length;
                    //helper end

                } else {
                    this.orderSubject.next({});
                }
            } else {
                this.orderSubject.next({});
            }
            this.orderRawSubject.next(JSON.stringify(this.orderSubject.value))
        } catch (e) {
            Sentry.withScope((scope) => {
                scope.setTag("userId", this.userSvc.getUser().user.id)
                scope.setTag("userEmail", this.userSvc.getUser().user.email)
                Sentry.setExtra('Error Message', e)
                Sentry.captureException('CHECK ORDER ERR: ' + JSON.stringify(e));
            })
        }
    }

    public async updateDayOrderOnDeliveryChange(dayOrder: any, key: string, deliveryOptions: ProviderListModel, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number): Promise<void> {
        if (dayOrder.delivery.selected === DeliveryTypeEnum.TAKEAWAY && !dayOrder.branch && deliveryOptions[deliveryOptions.delivery.selected_branch]) {
            dayOrder.branch = deliveryOptions.delivery.selected_branch;
        } else {
            if (!dayOrder.branch) {
                dayOrder.branch = Object.keys(deliveryOptions.delivery.branches)[0];
            }
        }
        if (dayOrder.delivery.selected === DeliveryTypeEnum.DELIVERY) {
            dayOrder.delivery.price = this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice);
        } else {
            dayOrder.delivery.price = 0;
        }
        const orders = this.getOrder();
        orders[key] = dayOrder;
        this.orderSubject.next(orders);
        await this.storageSvc.setItem('userOrder', JSON.stringify(orders));

    }

    public setDefaultDelivery(deliveryOptions: DeliveryOptionsModel): void {
        const order = this.getOrder();
        for (const day in order) {
            if (!order[day].delivery.selected) {
                if (deliveryOptions.delivery_options[DeliveryTypeEnum.TAKEAWAY] && deliveryOptions.selected_branch && deliveryOptions.selected_delivery === DeliveryTypeEnum.TAKEAWAY) {
                    order[day].delivery.selected = DeliveryTypeEnum.TAKEAWAY;
                    order[day].branch = deliveryOptions.selected_branch;
                } else {
                    order[day].branch = null;
                    if (deliveryOptions.delivery_options.hasOwnProperty(deliveryOptions.selected_delivery)) {
                        order[day].delivery.selected = deliveryOptions.selected_delivery;
                    } else {
                        order[day].delivery.selected = Object.keys(deliveryOptions.delivery_options)[0];
                    }
                    if (order[day].delivery.selected === DeliveryTypeEnum.TAKEAWAY) {
                        order[day].branch = Object.keys(deliveryOptions.branches)[0]
                    }
                }
            }
        }
        this.orderSubject.next(order);
    }

    getDailyTransportRate(key: string, providerDeliveryRateList: Array<DeliveryRateModel>, mealPrice: number = null): number {
        const orderDailyTotal = key ? this.getDailyOrderTotal(key) : mealPrice;
        if (providerDeliveryRateList.length) {
            return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderDailyTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderDailyTotal)[0].price);
        }
    }

    getHistoryDailyTransportRate(key: string, providerDeliveryRateList: Array<DeliveryRateModel>, history: any): number {
        const orderDailyTotal = this.getHistoryDailyOrderTotal(key, history);
        if (providerDeliveryRateList.length) {
            return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderDailyTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderDailyTotal)[0].price);
        }
    }

    getTransportRate(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, history = null): number {
        let orderTotal = this.getOrderTotal();
        if (history) {
            orderTotal = 0
            for (const historyMeal of Object.keys(history)) {
                for (let dayMeal of history[historyMeal]) {
                    orderTotal += (Number(dayMeal.price) + Number(dayMeal.case_price))
                }
            }
        }
        if (providerDeliveryRateList.length) {
            if ((history ? this.getHistoryDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice, history) : this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice)) > 0) {
                return Number(providerDeliveryRateList.filter(d => Number(d.order_price_from) <= orderTotal && Number(d.order_price_to ? d.order_price_to : '1000000') >= orderTotal)[0].price);
            } else {
                return 0;
            }
        } else {
            return history ? this.getHistoryDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice, history) : this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice)
        }
    }

    public getOrderDeliveryTotal(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, meal: any = null): number {
        let deliveryTotal: number = 0;
        if (this.userSvc.allowedMultiple) {
            const order = this.getOrder();
            if (Object.keys(order).length) {
                for (const day in order) {
                    const index = Object.keys(order[day].orders).findIndex(o => order[day].orders[o].qty > 0);
                    if (index > -1) {
                        if (order[day].delivery.selected && order[day].delivery.selected === DeliveryTypeEnum.DELIVERY) {
                            if (clientDeliveryPrice) {
                                deliveryTotal += clientDeliveryPrice;
                            } else {
                                deliveryTotal += this.getDailyTransportRate(day, providerDeliveryRateList);
                            }
                        }
                    } else {
                        if (meal) {
                            deliveryTotal += this.getDailyTransportRate(null, providerDeliveryRateList, meal.userData ? meal.userData.price : Number(meal.price));
                        }
                    }
                }
            } else {
                if (meal) {
                    deliveryTotal = this.getDailyTransportRate(null, providerDeliveryRateList, meal.userData ? meal.userData.price : Number(meal.price));
                } else {
                    if (clientDeliveryPrice) {
                        deliveryTotal = clientDeliveryPrice;
                    }
                }
            }
        }

        return deliveryTotal;
    }

    public getHistoryDeliveryTotal(providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, history: any): number {
        let deliveryTotal: number = 0;
        const order = history;
        for (const day of Object.keys(order)) {
            if (order[day] && order[day][0] && order[day][0].deliver === '1') {//is delivery
                if (clientDeliveryPrice) {
                    deliveryTotal += clientDeliveryPrice;
                } else {
                    deliveryTotal += this.getHistoryDailyTransportRate(day, providerDeliveryRateList, history);
                }
            }
        }
        return deliveryTotal;
    }

    public getTotalToPay(deliveryOptions: ProviderListModel, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number, ordersHistory: Array<HistoryOrderModel>): number {
        const order = this.getOrder();
        let orderPrice: number = 0;
        let historyPrice: number = 0;
        let historyDeliveryPrice = 0
        if (ordersHistory) {
            historyPrice = this.getAlreadyOrderedAndPaid(ordersHistory, providerDeliveryRateList, clientDeliveryPrice)
            const groupedHistoryByDay = this.helperSvc.groupByKey(ordersHistory, 'lunchDate')

            for (const historyDay of Object.keys(groupedHistoryByDay)) {
                if (groupedHistoryByDay[historyDay][0].deliver === '1') {
                    historyDeliveryPrice += historyDeliveryPrice + this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice, groupedHistoryByDay)
                }
            }
        }
        if (order) {
            for (const keyDay of Object.keys(order)) {
                for (const keyOrder of Object.keys(order[keyDay].orders)) {
                    let qty = order[keyDay].orders[keyOrder].qty
                    const casePrice = (deliveryOptions.box === CaseTypeEnum.DISPOSABLE_STRING ? deliveryOptions.box_oneuse_price : 0);
                    orderPrice = orderPrice + (qty * Number(order[keyDay].orders[keyOrder].price)) + (order[keyDay].orders[keyOrder].box * casePrice);
                }
            }
        }
        const delivery = ((this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice) - historyDeliveryPrice) >= historyDeliveryPrice) ? (this.getOrderDeliveryTotal(providerDeliveryRateList, clientDeliveryPrice) - historyDeliveryPrice) : historyDeliveryPrice
        return orderPrice - historyPrice < 0 ? 0 : orderPrice - historyPrice + delivery;
    }


    public getAlreadyOrderedAndPaid(ordersHistory: Array<HistoryOrderModel>, providerDeliveryRateList: Array<DeliveryRateModel>, clientDeliveryPrice: number): number {
        let historyPrice = 0
        const groupedHistoryByDay = this.helperSvc.groupByKey(ordersHistory, 'lunchDate')

        for (const historyMeal of ordersHistory) {
            historyPrice += (Number(historyMeal.price) + Number(historyMeal.case_price))
        }
        return historyPrice + this.getTransportRate(providerDeliveryRateList, clientDeliveryPrice, groupedHistoryByDay)
    }

    public async updateMealQty(date: string, mealId: string, qty: number, provider: ProviderListModel, showAlert: boolean = true, userBalance: any, orderRaw: string = null, ordersHistory: any = null, menu: any = null): Promise<void> {
        await Haptics.impact({style: ImpactStyle.Medium});
        const order = this.getOrder();
        if (this.userSvc.allowedMultiple) {
            if (order[date].orders[mealId].qty === 1 && qty === -1 && showAlert) {
                await this.presentRemoveMealAlert(order, date, mealId, provider, ordersHistory)
            } else {
                order[date].orders[mealId].qty = order[date].orders[mealId].qty + qty;
                this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value + qty);
                if (order[date].orders[mealId].box > order[date].orders[mealId].qty) {
                    order[date].orders[mealId].box = order[date].orders[mealId].qty;
                }
                if (Number(order[date].orders[mealId].optionalCase) === 0 && provider.box === CaseTypeEnum.DISPOSABLE_STRING) {
                    order[date].orders[mealId].box = order[date].orders[mealId].qty;
                }
                if (qty > 0) {
                    if (orderRaw) {
                        if (JSON.parse(orderRaw)[date] && JSON.parse(orderRaw)[date].orders && JSON.parse(orderRaw)[date].orders[mealId]) {
                            if (order[date].orders[mealId].qty > JSON.parse(orderRaw)[date].orders[mealId].qty) {
                                this.addToAddedMealToOrder(order[date].orders[mealId], provider, userBalance, true,menu)
                            }
                        } else {
                            this.addToAddedMealToOrder(order[date].orders[mealId], provider, userBalance, true,menu)
                        }
                    } else {
                        this.addToAddedMealToOrder(order[date].orders[mealId], provider, userBalance, true,menu)
                    }
                } else {
                    this.removeFromAddedMealToOrder(order[date].orders[mealId])
                }
                await this.updateOrder(order, provider.id);

                if (ordersHistory) {
                    const mealHistory = ordersHistory.find(meal => meal.lunchId === mealId)
                    const mealHistoryCount = this.helperSvc.groupByKey(ordersHistory, 'lunchId')[mealId]?.length
                    if (mealHistory) {
                        if (qty > 0) {
                            if (this.userBalanceExt > 0) {
                                this.userBalanceExt -= Number(mealHistory.price)
                                this.userBalanceExt -= Number(mealHistory.case_price)
                            }
                        } else {
                            if (order[date].orders[mealId].qty < mealHistoryCount) {
                                this.userBalanceExt += Number(mealHistory.price)
                                if (mealHistory.box === CaseTypeEnum.DISPOSABLE_STRING) {
                                    this.userBalanceExt += Number(mealHistory.case_price)
                                }
                            }
                        }
                    }
                }
            }
        } else {

        }
    }

    public async updateMealQtyInput(date: string, mealId: string, provider: ProviderListModel, event: any): Promise<void> {
        const order = this.getOrder();
        if (!event.detail.value) {
            await this.presentRemoveMealAlert(order, date, mealId, provider)
        } else {
            order[date].orders[mealId].qty = Number(event.detail.value)
        }
    }

    public async updateMealBoxQty(date: string, mealId: string, qty: number, provider: ProviderListModel, menu: any, ordersHistory: any = null): Promise<void> {
        await Haptics.impact({style: ImpactStyle.Medium});
        const order = this.getOrder();

        if (provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE_STRING) {
            let boxPrice = Number(provider.box_oneuse_price);
            if (menu[date].find(m => m.id === mealId).onlyOne !== '1') {
                boxPrice = 0
            }
            order[date].orders[mealId].boxPrice = boxPrice;
        }
        order[date].orders[mealId].box = Number(order[date].orders[mealId].box) + qty;
        await this.updateOrder(order, provider.id);

        if (ordersHistory && ordersHistory.length) {
            const mealHistory = ordersHistory.find(meal => meal.lunchId === mealId)
            if (mealHistory) {
                if (mealHistory.box === CaseTypeEnum.DISPOSABLE_STRING) {
                    this.userBalanceExt += (Number(mealHistory.case_price)) * qty
                } else if (mealHistory.box === CaseTypeEnum.NONE_STRING && provider.box === CaseTypeEnum.DISPOSABLE_STRING) {
                    this.userBalanceExt -= (Number(provider.box_oneuse_price)) * qty
                }
            }
        } else {
            if (menu[date].find(m => m.id === mealId).onlyOne === '1') {
                this.addedBoxesToOrder += qty
            }
        }
    }

    async presentRemoveMealAlert(order: any, date: string, mealId: string, provider: ProviderListModel, ordersHistory: any = null) {
        const alert = await this.alertCtrl.create({
            header: this.translateSvc.instant('alert.header.meal-remove'),
            subHeader: order[date].orders[mealId].name,
            buttons: [
                {
                    text: this.translateSvc.instant('alert.button.cancel'),
                    role: 'cancel',
                    handler: async () => {
                        order[date].orders[mealId].qty = 1;
                        order[date].orders[mealId].box = !provider.optional_case && provider.box === CaseTypeEnum.DISPOSABLE_STRING ? 1 : 0;
                        await this.updateOrder(order, provider.id);
                    }
                },
                {
                    text: this.translateSvc.instant('alert.button.remove'),
                    role: 'confirm',
                    handler: async () => {
                        if (order[date]) { //check if order includes selected meal date
                            if (order[date].orders[mealId]) { //check if order date includes selected meals
                                this.removeFromAddedMealToOrder(order[date].orders[mealId])
                                delete order[date].orders[mealId]
                                /*
                                order[date].orders[mealId].qty = 0
                                order[date].orders[mealId].box = 0*/
                            }
                            const index = Object.keys(order[date].orders).findIndex(o => order[date].orders[o].qty > 0);
                            if (index < 0) {
                                order[date].delivery.price = 0
                            }
                            this.helperSvc.orderNewMeals.next(this.helperSvc.orderNewMeals.value - 1);
                            if (ordersHistory) {
                                const mealHistory = ordersHistory.find(meal => meal.lunchId === mealId)
                                if (mealHistory) {
                                    this.userBalanceExt += Number(mealHistory.price)
                                    if (mealHistory.box === CaseTypeEnum.DISPOSABLE_STRING) {
                                        this.userBalanceExt += Number(mealHistory.case_price)

                                    }
                                }
                            }
                        }
                        await this.updateOrder(order, provider.id);
                        console.log("ORDR: ", this.getOrder())
                    }
                }
            ]
        });
        await alert.present();
    }

    async updateOrder(order, providerId: string | number) {
        const userOrder = JSON.parse(await this.storageSvc.getItem('userOrder'));
        this.orderSubject.next(order);
        if (order === null) {
            delete userOrder[providerId];
        } else {
            userOrder[providerId] = order;
        }
        await this.storageSvc.setItem('userOrder', JSON.stringify(userOrder));
    }

    isDayMealBox(day: string): boolean {
        const order = this.getOrder();
        if (Object.keys(order[day].orders)) {
            for (let meal of Object.keys(order[day].orders)) {
                if (order[day].orders[meal].box) {
                    return true
                }
            }
        }
        return false
    }

    async onBoxChange(day: string, event: any, provider: ProviderListModel): Promise<void> {
        const order = this.getOrder();
        if (Object.keys(order[day].orders)) {
            for (let meal of Object.keys(order[day].orders)) {
                order[day].orders[meal].box = event.target.checked;
                order[day].orders[meal].boxPrice = (provider.box === CaseTypeEnum.DISPOSABLE_STRING && event.target.checked) ? provider.box_oneuse_price : 0;
            }
        }
        await this.updateOrder(order, provider.id);
    }

    isDayOrderEmpty(day: string): boolean {
        const order = this.getOrder();
        const index = Object.keys(order[day].orders).findIndex(o => order[day].orders[o].qty > 0);
        return index <= -1;
    }


    showMealAvailable(order: any, date: string, mealId: string, availableCount: string): boolean {
        if (availableCount) {
            return (Number(availableCount) / 4) >= Number(availableCount) - this.getMealCount(order, date, mealId);
        }
        return false;
    }

    getMealAvailable(order: any, date: string, mealId: string, availableCount: string): number {
        if (availableCount && (Number(availableCount) / 4) >= Number(availableCount) - this.getMealCount(order, date, mealId)) {
            return Number(availableCount) - this.getMealCount(order, date, mealId)
        }
    }

    getMealCount(order: any, date: string, mealId: string) {
        if (order)
            return order[date] ? order[date].orders[mealId] ? order[date].orders[mealId].qty : 0 : 0;
    }

    isAddedNewMealOrBox(): boolean {
        const order = this.getOrder()
        const orderRaw = this.orderRawSubject.value ? JSON.parse(this.orderRawSubject.value) : null
        let orderQty = 0;
        let orderBoxQty = 0;
        let orderRawQty = 0;
        let orderRawBoxQty = 0;

        if (order) {
            for (const keyDay of Object.keys(order)) {
                for (const keyOrder of Object.keys(order[keyDay].orders)) {
                    orderQty = orderQty + order[keyDay].orders[keyOrder].qty;
                    orderBoxQty = orderBoxQty + order[keyDay].orders[keyOrder].box;
                }
            }
            if (orderRaw) {
                for (const keyDay of Object.keys(orderRaw)) {
                    for (const keyOrder of Object.keys(orderRaw[keyDay].orders)) {
                        orderRawQty = orderRawQty + orderRaw[keyDay].orders[keyOrder].qty;
                        orderRawBoxQty = orderRawBoxQty + orderRaw[keyDay].orders[keyOrder].box;
                    }
                }
            }

            return (orderQty > orderRawQty) || (orderBoxQty > orderRawBoxQty)
        }
    }

    mealAvailable(dayMenu: any, mealId: string, menu: any = null) {
        return dayMenu.find(m => m.id === mealId).available
    }

    getHistoryMealPrices(meal: any) {
        return this.helperSvc.groupByKey(meal, 'price');
    }


    ordersDisabled(meal, provider, userBalance, deliveryPrice: number = 0, menu: any = null): boolean {
        if (provider.no_debt) {
            if ((Number(userBalance) + this.userBalanceExt) <= 0) {
                return true
            }
            let casePrice = 0;

            if (provider.box === CaseTypeEnum.DISPOSABLE_STRING) {
                casePrice = provider.box_oneuse_price
            }
            let addedMealsPrice = 0
            /*  if (meal) {
                  addedMealsPrice = this.addToAddedMealToOrder(meal, provider, (Number(userBalance)))
              } else {*/
            this.addedMealsToOrder.forEach((meal) => {
                addedMealsPrice += (meal.userData ? meal.userData.price : Number(meal.price))
            })
            if (provider.box === CaseTypeEnum.DISPOSABLE_STRING) {
                addedMealsPrice += this.addedBoxesToOrder * casePrice
            }

            //}
            const orderTotal = this.getOrderTotal(casePrice, menu)
            /* console.warn("deliveryPrice", deliveryPrice)
                console.warn("addedMealsPrice", addedMealsPrice)*/
            /*   console.warn("orderTotal", orderTotal)
               console.warn("balance: ", (Number(userBalance) + this.userBalanceExt))
               console.warn("================")*/
            //console.warn(orderTotal - (orderTotal - addedMealsPrice) + deliveryPrice)
            /* console.warn(addedMealsPrice)
             console.warn(deliveryPrice)*/
            return (orderTotal - (orderTotal - addedMealsPrice) + deliveryPrice) > (Number(userBalance) + this.userBalanceExt);

        } else {
            return false
        }
    }

    addToAddedMealToOrder(meal, providerBase, userBalance, defaultAdd: boolean = false,menu: any = null) {
        let casePrice = 0;
        if (providerBase.box === CaseTypeEnum.DISPOSABLE_STRING) {
            casePrice = providerBase.box_oneuse_price
        }
        let addedMealsPrice = 0;
        this.addedMealsToOrder.forEach((meal) => {
            addedMealsPrice += (meal.userData ? meal.userData.price : Number(meal.price))
        })
        if (defaultAdd) {
            this.addedMealsToOrder.push(meal)
        }
        if (!defaultAdd && meal && (this.getOrderTotal(casePrice, menu) - (this.getOrderTotal(casePrice, menu) - addedMealsPrice - (meal.userData ? meal.userData.price : Number(meal.price)))) <= (Number(userBalance) + this.userBalanceExt)) {
            this.addedMealsToOrder.push(meal)
        }

        return addedMealsPrice += (meal.userData ? meal.userData.price : Number(meal.price))
    }

    removeFromAddedMealToOrder(meal) {
        const index = this.addedMealsToOrder.findIndex(m => m.id.toString() === meal.id.toString())
        if (index > -1) {
            this.addedMealsToOrder.splice(index, 1)
        }
    }


    removedMealFromOrder(order, orderRaw, deliveryOptions) {
        if (order && orderRaw !== '{}') {
            const orderRawObj = JSON.parse(orderRaw)
            for (const keyDay of Object.keys(order)) {
                if (Object.keys(order[keyDay].orders) && Object.keys(order[keyDay].orders).length) {
                    for (const keyOrder of Object.keys(order[keyDay].orders)) {
                        if (orderRawObj[keyDay] && orderRawObj[keyDay].orders) {
                            if (orderRawObj[keyDay].orders[keyOrder]) {
                                if (deliveryOptions.box !== CaseTypeEnum.DISPOSABLE && deliveryOptions.box !== CaseTypeEnum.DISPOSABLE_STRING) {
                                    if (orderRawObj[keyDay].orders[keyOrder].qty > order[keyDay].orders[keyOrder].qty) {
                                        return true
                                    }
                                } else {
                                    if ((orderRawObj[keyDay].orders[keyOrder].qty > order[keyDay].orders[keyOrder].qty && orderRawObj[keyDay].orders[keyOrder].box === order[keyDay].orders[keyOrder].box) ||
                                        (orderRawObj[keyDay].orders[keyOrder].qty > order[keyDay].orders[keyOrder].qty && orderRawObj[keyDay].orders[keyOrder].box > order[keyDay].orders[keyOrder].box) ||
                                        (orderRawObj[keyDay].orders[keyOrder].box > order[keyDay].orders[keyOrder].box && orderRawObj[keyDay].orders[keyOrder].qty === order[keyDay].orders[keyOrder].qty)) {
                                        return true
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (Object.keys(orderRawObj[keyDay].orders) && Object.keys(orderRawObj[keyDay].orders).length) {
                        return true
                    }
                }
            }
            return false
        } else {
            return false
        }
    }

    compareChanges(order, orderRaw): boolean {
        let orderText = ''
        let orderRawText = ''
        if (order) {
            orderText = JSON.parse(JSON.stringify(order))
            for (const keyDay of Object.keys(orderText)) {
                for (const keyOrder of Object.keys(orderText[keyDay].orders)) {
                    if (orderText[keyDay].orders[keyOrder]) {
                        delete orderText[keyDay].orders[keyOrder].boxPrice;
                        if (!this.userSvc.allowedMultiple) {
                            if (orderText[keyDay].orders[keyOrder].box === true || orderText[keyDay].orders[keyOrder].box === 1) {
                                orderText[keyDay].orders[keyOrder].box = 1
                            } else {
                                orderText[keyDay].orders[keyOrder].box = 0
                            }
                        }
                    }
                }
            }
        }
        if (orderRaw) {
            orderRawText = JSON.parse(orderRaw)
            for (const keyDay of Object.keys(orderRawText)) {
                for (const keyOrder of Object.keys(orderRawText[keyDay].orders)) {
                    if (orderRawText[keyDay].orders[keyOrder]) {
                        delete orderRawText[keyDay].orders[keyOrder].boxPrice
                    }
                }
            }
        }
        return JSON.stringify(orderText) !== JSON.stringify(orderRawText)
    }
}
