import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationsService {
    public activeLngSubject: BehaviorSubject<string> = new BehaviorSubject<any>('');
    public activeLng: Observable<string> = this.activeLngSubject.asObservable();
    private lngSubscription: Subscription;

    public appLanguages: Array<string> = ['cs', 'en', 'sk'];

    constructor(
        private translateSvc: TranslateService,
        public storageSvc: StorageService
    ) {
        this.lngSubscription = this.activeLng.subscribe(async (resp) => {
            if (resp) {
                this.translateSvc.setDefaultLang(resp);
                this.translateSvc.use(resp);
                await this.saveSelectedLng(resp);
            }
        });
    }

    public async init(): Promise<void> {
        const selectedLng = await this.getSelectedLng();
        if (!selectedLng) {
            const deviceDefaultLng = (await Device.getLanguageCode()).value;
            if (this.appLanguages.includes(deviceDefaultLng)) {
                this.activeLngSubject.next(deviceDefaultLng);
            } else {
                this.activeLngSubject.next('cs');
            }
        } else {
            this.activeLngSubject.next(selectedLng);
        }
        this.translateSvc.addLangs(this.appLanguages);
    }

    public getActiveLng(): string {
        return this.activeLngSubject.value;
    }

    private async saveSelectedLng(lng: string) {
        await this.storageSvc.setItem('selectedLng', lng);
    }

    private async getSelectedLng(): Promise<string> {
        return await this.storageSvc.getItem('selectedLng');
    }

    public getCurrentLocale(): string {
        switch (this.activeLngSubject.value) {
            case 'cs':
                return 'cs-CZ';
                break;
            case 'en':
                return 'en-GB';
                break;
            case 'sk':
                return 'sk-SK';
                break;

        }
    }
}
