import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

// Init for the web
import { Platform } from '@ionic/angular';
import {UserService} from "./user.service";
import { initializeApp } from 'firebase/app';
import {FirebaseAnalytics, LogEventOptions} from "@capacitor-firebase/analytics";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(private router: Router,
              private platform: Platform,
              private userSvc: UserService
  ) {
    this.router.events.pipe(
      filter((e: any) => e instanceof NavigationEnd)
    ).subscribe(async (e: RouterEvent) => {
      await this.setScreenName(e.url);
    });
  }

  async initFb() {
    if (this.platform.platforms().includes('capacitor')) {
      return;
    } else {
      initializeApp(environment.firebaseConfig);

    }
  }

  async setUser() {
    // Use Firebase Auth uid
    await FirebaseAnalytics.setUserId({
      userId: this.userSvc.getUser().user.email
    });
    await this.setProperty({
      key: 'user-id',
      value: this.userSvc.getUser().user.id
    })
  }

  async setProperty(data) {
    await FirebaseAnalytics.setUserProperty(data);
  }

  async logEvent(event: LogEventOptions) {
    await FirebaseAnalytics.logEvent(event);
  }

  async setScreenName(screenName: string) {
    await FirebaseAnalytics.setCurrentScreen({
      screenName: screenName,
    });
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setEnabled({
      enabled: this.analyticsEnabled
    });
  }

}
