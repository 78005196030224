import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent {
    forgotPasswordForm: FormGroup;

    validation_messages = {
        email: [
            { type: 'required', message: 'form.input.email.empty_error' },
            { type: 'pattern', message: 'form.input.email.format_error' }
        ]
    };

    constructor(
        public router: Router,
        public menu: MenuController,
        private modalCtrl: ModalController
    ) {
        this.forgotPasswordForm = new FormGroup({
            'email': new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ]))
        });
    }

    // Disable side menu for this page
    ionViewDidEnter(): void {
        this.menu.enable(false);
    }

    async close() {
        await this.modalCtrl.dismiss();
    }

    // Restore to default when leaving this page
    ionViewDidLeave(): void {
        this.menu.enable(true);
    }

    recoverPassword(): void {
        console.log(this.forgotPasswordForm.value);
        this.close();
        //this.router.navigate(['app/categories']);
    }

}
