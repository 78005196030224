export enum MyRbacOperations {
    Account_DisableTour = 'Account:DisableTour',
    Account_actionAddCreditCard = 'Account:AddCreditCard',
    Account_actionArchive = 'Account:archive',
    Account_actionClientRequests = 'Account:ClientRequests',
    Account_actionClients = 'Account:clients',
    Account_actionCreate = 'Account:create',
    Account_actionCreateBilling = 'Account:CreateBilling',
    Account_actionDelete = 'Account:delete',
    Account_actionDeleteBilling = 'Account:DeleteBilling',
    Account_actionDeleteCreditCard = 'Account:DeleteCreditCard',
    Account_actionDeleteFirm = 'Account:DeleteFirm',
    Account_actionDeleteTestFirms = 'Account:DeleteTestFirms',
    Account_actionEditable = 'Account:editable',
    Account_actionFileUploaderConnector = 'Account:FileUploaderConnector',
    Account_actionGeolocate = 'Account:Geolocate',
    Account_actionImport = 'Account:Import',
    Account_actionIndex = 'Account:Index',
    Account_actionListOfAccounts = 'Account:listOfAccounts',
    Account_actionPaymentMethods = 'Account:PaymentMethods',
    Account_actionRestore = 'Account:Restore',
    Account_actionUpdate = 'Account:update',
    Account_actionView = 'Account:view',
    Account_canAccessPaymentMethodsForAnyAccount = 'Account:canAccessPaymentMethodsForAnyAccount',
    Account_canAddCreditCardForAnyAccount = 'Account:canAddCreditCardForAnyAccount',
    Account_canArchiveAll = 'Account:canArchiveAll',
    Account_canArchiveTheSameAccount = 'Account:canArchiveTheSameAccount',
    Account_canCreateBillingForAnyAccount = 'Account:canCreateBillingForAnyAccount',
    Account_canDeleteAnyAccount = 'Account:canDeleteAnyAccount',
    Account_canDeleteBillingForAnyAccount = 'Account:canDeleteBillingForAnyAccount',
    Account_canDeleteCreditCardForAnyAccount = 'Account:canDeleteCreditCardForAnyAccount',
    Account_canDumpOwnClients = 'Account:canDumpOwnClients',
    Account_canEditStripeAccountId = 'Account:canEditStripeAccountId',
    Account_canSendActivationEmail = 'Account:canSendActivationEmail',
    Account_canUpdateAll = 'Account:canUpdate',
    Account_canUpdateAnyAccount = 'Account:canUpdateAnyAccount',
    Account_canUpdateBillingNote = 'Account:canUpdateBillingNote',
    Account_canUpdateBoxType = 'Account:canUpdateBoxType',
    Account_canUpdateClientDeliveryPrice = 'Account:canUpdateClientDeliveryPrice',
    Account_canUpdateClientsAccount = 'Account:canUpdateClientsAccount',
    Account_canUpdateDefaultBranch = 'Account:canUpdateDefaultBranch',
    Account_canUpdateDefaultDelivery = 'Account:canUpdateDefaultDelivery',
    Account_canUpdateDenyFirmUpdate = 'Account:canUpdateDenyFirmUpdate',
    Account_canUpdateDiscount = 'Account:canUpdateDiscount',
    Account_canUpdateInvoiceShowStats = 'Account:canUpdateInvoiceShowStats',
    Account_canUpdateNoDebt = 'Account:canUpdateNoDebt',
    Account_canUpdateOwnAccount = 'Account:canUpdateOwnAccount',
    Account_canUpdatePrecision = 'Account:canUpdatePrecision',
    Account_canUpdateProviders = 'Account:canUpdateProviders',
    Account_canUpdateRoute = 'Account:canUpdateRoute',
    Account_canUpdateStreetToAnyValue = 'Account:canUpdateStreetToAnyValue',
    Account_canUpdateSubsidy = 'Account:canUpdateSubsidy',
    Account_canUpdateTelToAnyValue = 'Account:canUpdateTelToAnyValue',
    Account_canUpdateVatRate = 'Account:canUpdateVatRate',
    Account_canUpdateViaAdminForm = 'Account:canUpdateViaAdminForm',
    Account_canViewOwnAccount = 'Account:canViewOwnAccount',
    Address_actionCreate = 'Address:create',
    Address_actionDelete = 'Address:delete',
    Address_actionIndex = 'Address:Index',
    Address_actionUpdate = 'Address:update',
    Address_actionView = 'Address:View',
    Api_account_canGetClients = 'Api:account:canGetClients',
    Api_account_canSetAddress = 'Api:account:canSetAddress',
    Api_account_canSetDeliveryPolygons = 'Api:account:canSetDeliveryPolygons',
    Api_actionDelete = 'Api:Delete',
    Api_actionIndex = 'Api:Index',
    Api_actionOrder = 'Api:Order',
    Api_actionAccount = 'Api:Account',
    Api_actionBoxMovement = 'Api:BoxMovement',
    Api_actionDeliveryRate = 'Api:DeliveryRate',
    Api_actionLogin = 'Api:Login',
    Api_actionLunch = 'Api:Lunch',
    Api_actionProvider = 'Api:Provider',
    Api_actionSystemUser = 'Api:SystemUser',
    Api_deliveryRate_canCreateDeliveryRate = 'Api:deliveryRate:canCreateDeliveryRate',
    BoxDeposit_actionAjaxCreate = 'BoxDeposit:ajaxCreate',
    BoxDeposit_actionCreate = 'BoxDeposit:create',
    BoxDeposit_actionDelete = 'BoxDeposit:delete',
    BoxDeposit_actionDeposits = 'BoxDeposit:deposits',
    BoxDeposit_actionUpdate = 'BoxDeposit:update',
    BoxDeposit_actionView = 'BoxDeposit:view',
    BoxMovement_actionAjaxCreate = 'BoxMovement:ajaxCreate',
    BoxMovement_actionBoxes = 'BoxMovement:boxes',
    BoxMovement_actionCreate = 'BoxMovement:create',
    BoxMovement_actionDelete = 'BoxMovement:delete',
    BoxMovement_actionIndex = 'BoxMovement:index',
    BoxMovement_actionInsertMovements = 'BoxMovement:insertMovements',
    BoxMovement_actionMovementStats = 'BoxMovement:movementStats',
    BoxMovement_actionMovements = 'BoxMovement:movements',
    BoxMovement_actionUpdate = 'BoxMovement:update',
    BoxMovement_actionView = 'BoxMovement:view',
    BoxMovement_canAccessActionBoxesForAnyProvider = 'BoxMovement:canAccessActionBoxesForAnyProvider',
    BoxMovement_canAccessActionInsertMovementsForAnyProvider = 'BoxMovement:canAccessActionInsertMovementsForAnyProvider',
    BoxMovement_canAccessActionMovementStatsForAnyProvider = 'BoxMovement:canAccessActionMovementStatsForAnyProvider',
    BoxMovement_canAccessActionMovementsForAnyProvider = 'BoxMovement:canAccessActionMovementsForAnyProvider',
    BoxMovement_canAccessDepositsForAnyAccount = 'BoxMovement:canAccessDepositsForAnyAccount',
    Comment_actionCreate = 'Comment:Create',
    Comment_actionIndex = 'Comment:Index',
    Comment_actionAdmin = 'Comment:Admin',
    Comment_actionDelete = 'Comment:Delete',
    Comment_actionUpdate = 'Comment:Update',
    Comment_actionView = 'Comment:View',
    Controller_canSeeLayoutAdmin = 'Controller:canSeeLayoutAdmin',
    Controller_canSeeLayoutFirm = 'Controller:canSeeLayoutFirm',
    Controller_canSeeLayoutProvider = 'Controller:canSeeLayoutProvider',
    Controller_canSeeLayoutUser = 'Controller:canSeeLayoutUser',
    Dashboard_actionAdmin = 'Dashboard:admin',
    Dashboard_actionFirm = 'Dashboard:firm',
    Dashboard_actionIndex = 'Dashboard:index',
    Dashboard_actionProvider = 'Dashboard:provider',
    Dashboard_actionUser = 'Dashboard:user',
    DataStore_FileUploaderConnector = 'DataStore:FileUploaderConnector',
    DataStore_actionCKEditor = 'DataStore:CKEditor',
    DataStore_actionSingle = 'DataStore:Single',
    EmailNotification_actionSendProviderMonthReminder = 'EmailNotification:SendProviderMonthReminder',
    EmailNotification_actionSendProviderTrialExpireReminder = 'EmailNotification:SendProviderTrialExpireReminder',
    Gii_Default_Error = 'Gii:Default:Error',
    Gii_Default_Index = 'Gii:Default:Index',
    Gii_Default_Login = 'Gii:Default:Login',
    Gii_Default_Logout = 'Gii:Default:Logout',
    LdThemeAssignment_actionCreate = 'LdThemeAssignment:Create',
    LdThemeAssignment_actionDelete = 'LdThemeAssignment:Delete',
    LdThemeAssignment_actionIndex = 'LdThemeAssignment:Index',
    LdThemeAssignment_actionUpdate = 'LdThemeAssignment:Update',
    LdThemeAssignment_actionView = 'LdThemeAssignment:View',
    LdTheme_actionCreate = 'LdTheme:Create',
    LdTheme_actionDelete = 'LdTheme:Delete',
    LdTheme_actionFileUploaderConnector = 'LdTheme:FileUploaderConnector',
    LdTheme_actionIndex = 'LdTheme:Index',
    LdTheme_actionUpdate = 'LdTheme:Update',
    LdTheme_actionView = 'LdTheme:View',
    Likedislike_Default_Index = 'Likedislike:Default:Index',
    Likedislike_Default_Likedislike = 'Likedislike:Default:Likedislike',
    Lunch_actionCreate = 'Lunch:create',
    Lunch_actionCreatePermanent = 'Lunch:createPermanent',
    Lunch_actionDelete = 'Lunch:Delete',
    Lunch_actionEmail = 'Lunch:email',
    Lunch_actionIndex = 'Lunch:Index',
    Lunch_actionMenu = 'Lunch:Menu',
    Lunch_actionPdf = 'Lunch:Pdf',
    Lunch_actionRating = 'Lunch:Rating',
    Lunch_actionUpdate = 'Lunch:Update',
    Lunch_actionView = 'Lunch:view',
    Lunch_actionViewPermanent = 'Lunch:viewPermanent',
    Lunch_canAlwaysCancelOrderForOwnLunch = 'Lunch:canAlwaysCancelOrderForOwnLunch',
    Lunch_canAlwaysPlaceOrderForOwnLunch = 'Lunch:canAlwaysPlaceOrderForOwnLunch',
    MealCategory_actionCreate = 'MealCategory:create',
    MealCategory_actionDelete = 'MealCategory:delete',
    MealCategory_actionIndex = 'MealCategory:index',
    MealCategory_actionList = 'MealCategory:list',
    MealCategory_actionMassChange = 'MealCategory:massChange',
    MealCategory_actionUpdate = 'MealCategory:update',
    MealCategory_actionView = 'MealCategory:View',
    Meal_actionCreate = 'Meal:create',
    Meal_actionDelete = 'Meal:delete',
    Meal_actionImport = 'Meal:import',
    Meal_actionIndex = 'Meal:index',
    Meal_actionProviderMealList = 'Meal:ProviderMealList',
    Meal_actionStoryousImport = 'Meal:storyousImport',
    Meal_actionUpdate = 'Meal:update',
    Meal_actionView = 'Meal:View',
    MenuTemplate_actionCreate = 'MenuTemplate:Create',
    MenuTemplate_actionDelete = 'MenuTemplate:Delete',
    MenuTemplate_actionIndex = 'MenuTemplate:Index',
    MenuTemplate_actionUpdate = 'MenuTemplate:Update',
    MenuTemplate_actionView = 'MenuTemplate:View',
    News_actionCreate = 'News:create',
    News_actionDelete = 'News:delete',
    News_actionIndex = 'News:index',
    News_actionUpdate = 'News:update',
    News_actionView = 'News:View',
    Noticeboard_actionIndex = 'Noticeboard:Index',
    Order_actionAdminProvider = 'Order:adminProvider',
    Order_actionAdminProviderXlsExport = 'Order:adminProviderXlsExport',
    Order_actionBulkCreate = 'Order:BulkCreate',
    Order_actionBulkCreateDay = 'Order:bulkCreateDay',
    Order_actionBulkOrderNoteEdit = 'Order:bulkOrderNoteEdit',
    Order_actionCanteen = 'Order:Canteen',
    Order_actionCasePrice = 'Order:CasePrice',
    Order_actionChangeOrder = 'Order:ChangeOrder',
    Order_actionCreate = 'Order:Create',
    Order_actionDelete = 'Order:Delete',
    Order_actionDeleteOrder = 'Order:DeleteOrder',
    Order_actionFirm = 'Order:firm',
    Order_actionGo = 'Order:go',
    Order_actionHistory = 'Order:History',
    Order_actionIndex = 'Order:index',
    Order_actionMultiCreate = 'Order:multiCreate',
    Order_actionMultiUserOrder = 'Order:multiUserOrder',
    Order_actionOrders = 'Order:Orders',
    Order_actionLunchMarket = 'Order:lunchMarket',
    Order_actionPlaceOrder = 'Order:PlaceOrder',
    Order_actionPostToStoryous = 'Order:postToStoryous',
    Order_actionProviderWeekly = 'Order:providerWeekly',
    Order_actionReprice = 'Order:reprice',
    Order_actionRepriceDelivery = 'Order:repriceDelivery',
    Order_actionSpent = 'Order:Spent',
    Order_actionSpentAccount = 'Order:spentAccount',
    Order_actionTerminal = 'Order:Terminal',
    Order_actionUpdate = 'Order:Update',
    Order_actionUserBulkCreate = 'Order:UserBulkCreate',
    Order_actionUserSearch = 'Order:UserSearch',
    Order_actionView = 'Order:View',
    Order_canAccessBulkCreateForOwnAccount = 'Order:canAccessBulkCreateForOwnAccount',
    Order_canAccessBulkCreateForOwnClients = 'Order:canAccessBulkCreateForOwnClients',
    Order_canAccessUserBulkCreateForAnyUser = 'Order:canAccessUserBulkCreateForAnyUser',
    Order_canAccessUserBulkCreateForOwnAccount = 'Order:canAccessUserBulkCreateForOwnAccount',
    Order_canAccessUserBulkCreateForOwnClients = 'Order:canAccessUserBulkCreateForOwnClients',
    Order_canAlwaysPlaceOrderInMultiUserOrder = 'Order:canAlwaysPlaceOrderInMultiUserOrder',
    Order_canBulkCreateDayForOwnAccount = 'Order:canBulkCreateDayForOwnAccount',
    Order_canChangeOrderForOwnLunches = 'Order:canChangeOrderForOwnLunches',
    Order_canChangeOrderForOwnOrders = 'Order:canChangeOrderForOwnOrders',
    Order_canChangeProviderInMulticreate = 'Order:canChangeProviderInMulticreate',
    Order_canCreateOrdersForOwnAccount = 'Order:canCreateOrdersForOwnAccount',
    Order_canCreateOrdersForOwnClients = 'Order:canCreateOrdersForOwnClients',
    Order_canMultiCreateForEmployees = 'Order:canMultiCreateForEmployees',
    Order_canMultiCreateForOwnAccount = 'Order:canMultiCreateForOwnAccount',
    Order_canPlaceCheckBoxOrderInMultiCreate = 'Order:canPlaceCheckBoxOrderInMultiCreate',
    Order_canPlaceOrderForEmployees = 'Order:canPlaceOrderForEmployees',
    Order_canPostToStoryousForAll = 'Order:canPostToStoryousForAll',
    Order_canPostToStoryousForOwnClients = 'Order:canPostToStoryousForOwnClients',
    Order_canQuicklyOrderForADiner = 'Order:canQuicklyOrderForADiner',
    Order_canSeeAllAccountsAndClients = 'Order:canSeeAllAccountsAndClients',
    Order_canSeeOrdersOfAllOwnUsers = 'Order:canSeeOrdersOfAllOwnUsers',
    Order_canSeeProviderInListOfOrders = 'Order:canSeeProviderInListOfOrders',
    Order_canToggleCasePriceForOwnAccount = 'Order:canToggleCasePriceForOwnAccount',
    Order_canToggleCasePriceForOwnClients = 'Order:canToggleCasePriceForOwnClients',
    Order_canUseCurrentUserForBalanceOverviewInBulkCreate = 'Order:canUseCurrentUserForBalanceOverviewInBulkCreate',
    Order_canUseCurrentUserForBalanceOverviewInBulkCreateDay = 'Order:canUseCurrentUserForBalanceOverviewInBulkCreateDay',
    Order_canViewHistoryForOwnLunches = 'Order:canViewHistoryForOwnLunches',
    Order_canViewHistoryForOwnOrders = 'Order:canViewHistoryForOwnOrders',
    Order_canViewOrdersForOwnAccount = 'Order:canViewOrdersForOwnAccount',
    Order_canViewOrdersForOwnClients = 'Order:canViewOrdersForOwnClients',
    Order_canViewProviderWeeklyForAnyProvider = 'Order:canViewProviderWeeklyForAnyProvider',
    PaymentOrder_actionBillingStats = 'PaymentOrder:BillingStats',
    PaymentOrder_actionCheckInvoice = 'PaymentOrder:checkInvoice',
    PaymentOrder_actionCreate = 'PaymentOrder:Create',
    PaymentOrder_actionCreateInvoice = 'PaymentOrder:createInvoice',
    PaymentOrder_actionDelete = 'PaymentOrder:Delete',
    PaymentOrder_actionDeleteInvoice = 'PaymentOrder:deleteInvoice',
    PaymentOrder_actionGenerateForAll = 'PaymentOrder:GenerateForAll',
    PaymentOrder_actionImport = 'PaymentOrder:import',
    PaymentOrder_actionIndex = 'PaymentOrder:index',
    PaymentOrder_actionSendInvoice = 'PaymentOrder:sendInvoice',
    PaymentOrder_actionUpdate = 'PaymentOrder:Update',
    PaymentOrder_actionView = 'PaymentOrder:view',
    PaymentOrder_canAccessBillingStatsForAllAccounts = 'PaymentOrder:canAccessBillingStatsForAllAccounts',
    PaymentOrder_pdfInvoice = 'PaymentOrder:pdfInvoice',
    Payment_actionAssignPayments = 'Payment:assignPayments',
    Payment_actionCreate = 'Payment:create',
    Payment_actionCreateBulk = 'Payment:createBulk',
    Payment_actionDelete = 'Payment:delete',
    Payment_actionFirm = 'Payment:firm',
    Payment_actionIndex = 'Payment:index',
    Payment_actionPay = 'Payment:Pay',
    Payment_actionProvider = 'Payment:provider',
    Payment_actionReceipt = 'Payment:Receipt',
    Payment_actionStripeCheckPaymentIntent = 'Payment:StripeCheckPaymentIntent',
    Payment_actionStripeCreatePaymentIntent = 'Payment:StripeCreatePaymentIntent',
    Payment_actionStripeWebhook = 'Payment:StripeWebhook',
    Payment_actionUnassigned = 'Payment:unassigned',
    Payment_actionUpdate = 'Payment:update',
    Payment_actionUser = 'Payment:User',
    Payment_actionView = 'Payment:view',
    Payment_canAccessActionReceiptForAnyPayment = 'Payment:canAccessActionReceiptForAnyPayment',
    Payment_canCreatePayment = 'Payment:canCreatePayment',
    Payment_canCreatePaymentForOtherAccounts = 'Payment:canCreatePaymentForOtherAccounts',
    Payment_canEditPayments = 'Payment:canEditPayments',
    Payment_canSeeAnyAccount = 'Payment:canSeeAnyAccount',
    Payment_canSeeAnyAccountInActionFirm = 'Payment:canSeeAnyAccountInActionFirm',
    Payment_canSeeAnyProviderInActionProvider = 'Payment:canSeeAnyProviderInActionProvider',
    Payment_canSeeOwnClients = 'Payment:canSeeOwnClients',
    Payment_canSeePaymentUserFilter = 'Payment:canSeePaymentUserFilter',
    Payment_canSeePoInvoiceControlsInActionProvider = 'Payment:canSeePoInvoiceControlsInActionProvider',
    Payment_canSeePoInvoiceLinkInActionProvider = 'Payment:canSeePoInvoiceLinkInActionProvider',
    Payment_canSeeSelf = 'Payment:canSeeSelf',
    PermanentOffer_actionCreate = 'PermanentOffer:Create',
    PermanentOffer_actionDelete = 'PermanentOffer:Delete',
    PermanentOffer_actionIndex = 'PermanentOffer:Index',
    PermanentOffer_actionUpdate = 'PermanentOffer:Update',
    PermanentOffer_actionView = 'PermanentOffer:View',
    Rating_actionCreate = 'Rating:Create',
    Rating_actionDelete = 'Rating:Delete',
    Rating_actionIndex = 'Rating:Index',
    Rating_actionUpdate = 'Rating:Update',
    Rating_actionView = 'Rating:View',
    Rbac_actionCreateAuthItem = 'Rbac:CreateAuthItem',
    Rbac_actionIndex = 'Rbac:Index',
    Rbac_actionRoles = 'Rbac:Roles',
    Rbac_actionTasks = 'Rbac:Tasks',
    Rbac_actionUpdate = 'Rbac:Update',
    Rbac_canSeeAllTabsWithPermissionsAndRoles = 'Rbac:canSeeAllTabsWithPermissionsAndRoles',
    Recommend_actionCreate = 'Recommend:Create',
    Recommend_actionDelete = 'Recommend:Delete',
    Recommend_actionIndex = 'Recommend:Index',
    Recommend_actionUpdate = 'Recommend:Update',
    Recommend_actionView = 'Recommend:View',
    Register_actionCreate = 'Register:Create',
    Register_actionDelete = 'Register:Delete',
    Register_actionIndex = 'Register:Index',
    Register_actionUpdate = 'Register:Update',
    Register_actionView = 'Register:View',
    Report_actionAdmin = 'Report:admin',
    Report_actionExportAllDeliveryNotes = 'Report:ExportAllDeliveryNotes',
    Report_actionExportAllPrintLabels = 'Report:ExportAllPrintLabels',
    Report_actionExportAllSimpleDeliveryNotes = 'Report:ExportAllSimpleDeliveryNotes',
    Report_actionExportDeliveryNote = 'Report:ExportDeliveryNote',
    Report_actionExportPdf = 'Report:exportPdf',
    Report_actionExportPohoda = 'Report:exportPohoda',
    Report_actionExportPrintLabels = 'Report:ExportPrintLabels',
    Report_actionExportSimpleDeliveryNote = 'Report:ExportSimpleDeliveryNote',
    Report_actionFirm = 'Report:firm',
    Report_actionFirmDate = 'Report:firmdate',
    Report_actionIndex = 'Report:Index',
    Report_actionProvider = 'Report:provider',
    Report_actionUser = 'Report:User',
    Report_actionXlsReport = 'Report:xlsReport',
    Report_canAccessActionFirmForAllAccounts = 'Report:canAccessActionFirmForAllAccounts',
    Report_canAccessActionXlsExportForAllAccounts = 'Report:canAccessActionXlsExportForAllAccounts',
    Report_canChangeProviderInFirmAction = 'Report:canChangeProviderInFirmAction',
    ReportingModule_Job_actionAdd = 'Reporting:Job:Add',
    ReportingModule_Job_actionCreate = 'Reporting:Job:Create',
    ReportingModule_Job_actionDelete = 'Reporting:Job:Delete',
    ReportingModule_Job_actionEdit = 'Reporting:Job:Edit',
    ReportingModule_Job_actionGetInputForm = 'Reporting:Job:GetInputForm',
    ReportingModule_Job_actionGetJobSelectionForm = 'Reporting:Job:GetJobSelectionForm',
    ReportingModule_Job_actionList = 'Reporting:Job:List',
    ReportingModule_Job_actionOutput = 'Reporting:Job:Output',
    ReportingModule_Job_actionOutputs = 'Reporting:Job:Outputs',
    ReportingModule_Job_actionRun = 'Reporting:Job:Run',
    ReportingModule_Job_actionUpdate = 'Reporting:Job:Update',
    ReportingModule_Job_actionView = 'Reporting:Job:View',
    Route_actionCreate = 'Route:create',
    Route_actionDelete = 'Route:delete',
    Route_actionIndex = 'Route:index',
    Route_actionUpdate = 'Route:update',
    Route_actionView = 'Route:view',
    Site_actionChangelang = 'Site:Changelang',
    Site_actionError = 'Site:Error',
    Site_actionGuessCountry = 'Site:GuessCountry',
    Site_actionHelpFeedback = 'Site:HelpFeedback',
    Site_actionIndex = 'Site:Index',
    Site_actionJsi18n = 'Site:Jsi18n',
    Site_actionLogin = 'Site:Login',
    Site_actionLogout = 'Site:Logout',
    Site_actionMailTest = 'Site:MailTest',
    Site_actionOrders = 'Site:Orders',
    Site_actionRecommend = 'Site:Recommend',
    Site_actionRegister = 'Site:Register',
    Site_actionVatChange = 'Site:vatChange',
    Site_actionAcquire = 'Site:Acquire',
    Site_actionContact = 'Site:Contact',
    Site_actionContactlp = 'Site:Contactlp',
    Site_actionDodavatel = 'Site:Dodavatel',
    Site_actionFirm = 'Site:Firm',
    Site_actionProvider = 'Site:Provider',
    Site_actionProviderOld = 'Site:ProviderOld',
    Site_actionSearch = 'Site:Search',
    Site_canDisplayMoreDetailsInViewDodavatelInner = 'Site:canDisplayMoreDetailsInViewDodavatelInner',
    SystemUser_actionAllfirms = 'SystemUser:Allfirms',
    SystemUser_actionArchive = 'SystemUser:Archive',
    SystemUser_actionAttendance = 'SystemUser:Attendance',
    SystemUser_actionBarcodesPdf = 'SystemUser:BarcodesPdf',
    SystemUser_actionCreate = 'SystemUser:create',
    SystemUser_actionDelete = 'SystemUser:delete',
    SystemUser_actionFirm = 'SystemUser:Firm',
    SystemUser_actionImport = 'SystemUser:Import',
    SystemUser_actionIndex = 'SystemUser:index',
    SystemUser_actionMoveToAccount = 'SystemUser:MoveToAccount',
    SystemUser_actionLoginAs = 'SystemUser:LoginAs',
    SystemUser_actionRbac = 'SystemUser:rbac',
    SystemUser_actionRestore = 'SystemUser:Restore',
    SystemUser_actionScan = 'SystemUser:Scan',
    SystemUser_actionSearch = 'SystemUser:Search',
    SystemUser_actionSendInvitationEmail = 'SystemUser:SendInvitationEmail',
    SystemUser_actionUpdate = 'SystemUser:update',
    SystemUser_actionUpdateSelf = 'SystemUser:updateSelf',
    SystemUser_actionView = 'SystemUser:view',
    SystemUser_canArchiveAll = 'SystemUser:canArchiveAll',
    SystemUser_canArchiveClients = 'SystemUser:canArchiveClients',
    SystemUser_canArchiveTheSameAccount = 'SystemUser:canArchiveTheSameAccount',
    SystemUser_canBeUsedAsTheFirstFirmUser = 'SystemUser:canBeUsedAsTheFirstFirmUser',
    SystemUser_canEditHotelBarcode = 'SystemUser:canEditHotelBarcode',
    SystemUser_canEditHotelStatus = 'SystemUser:canEditHotelStatus',
    SystemUser_canInvite = 'SystemUser:canInvite',
    SystemUser_canLoginAsAnyOtherUser = 'SystemUser:canLoginAsAnyOtherUser',
    SystemUser_canPrintLabelsForAll = 'SystemUser:canPrintLabelsForAll',
    SystemUser_canPrintLabelsForOwnAccount = 'SystemUser:canPrintLabelsForOwnAccount',
    SystemUser_canSelfEditInCaseOfDenyFirmUpdate = 'SystemUser:canSelfEditInCaseOfDenyFirmUpdate',
    SystemUser_canUpdateAllUsers = 'SystemUser:canUpdateAllUsers',
    SystemUser_canUpdateBarcode = 'SystemUser:canUpdateBarcode',
    SystemUser_canUpdateEmailMenu = 'SystemUser:canUpdateEmailMenu',
    SystemUser_canUpdateStatus = 'SystemUser:canUpdateStatus',
    SystemUser_canUpdateUserRoles = 'SystemUser:canUpdateUserRoles',
    SystemUser_canUpdateUsersOfClients = 'SystemUser:canUpdateUsersOfClients',
    SystemUser_canUpdateUsersOfTheSameAccount = 'SystemUser:canUpdateUsersOfTheSameAccount',
    SystemUser_canUpdateWholeDetailForm = 'SystemUser:canUpdateWholeDetailForm',
    SystemUser_canViewAllUsers = 'SystemUser:canViewAllUsers',
    SystemUser_canViewUsersOfClients = 'SystemUser:canViewUsersOfClients',
    SystemUser_canViewUsersOfTheSameAccount = 'SystemUser:canViewUsersOfTheSameAccount',
    Token_actionCreate = 'Token:create',
    Token_actionDelete = 'Token:delete',
    Token_actionIndex = 'Token:index',
    Token_actionProvider = 'Token:provider',
    Token_actionIframe = 'Token:iframe',
    Token_actionLogin = 'Token:login',
    Token_actionUpdate = 'Token:update',
    Token_actionView = 'Token:view',
    UserModule_Activation_actionActivation = 'User:Activation:Activation',
    UserModule_Login_actionLogin = 'User:Login:Login',
    UserModule_Logout_actionLogout = 'User:Logout:Logout',
    UserModule_Recovery_actionRecovery = 'User:Recovery:Recovery',
    UserModule_Registration_actionAddUser = 'User:Registration:AddUser',
    UserModule_Registration_actionAddUsers = 'User:Registration:AddUsers',
    UserModule_Registration_canAddAccountToNewUser = 'Registration:canAddAccountToNewUser',
    UserModule_Registration_actionRegisterFirm = 'User:Registration:RegisterFirm',
    UserModule_Registration_actionRegisterRestaurant = 'User:Registration:RegisterRestaurant',
    UserModule_Registration_actionNewaccount = 'User:Registration:Newaccount',
    UserToken_actionAjaxCreate = 'UserToken:ajaxCreate',
    UserToken_actionDelete = 'UserToken:delete',
    UserToken_actionIndex = 'UserToken:Index',
    UserModule_Admin_actionAdmin = 'User:Admin:Admin',
    UserModule_Admin_actionCreate = 'User:Admin:Create',
    UserModule_Admin_actionDelete = 'User:Admin:Delete',
    UserModule_Admin_actionUpdate = 'User:Admin:Update',
    UserModule_Admin_actionView = 'User:Admin:View',
    UserModule_Default_actionIndex = 'User:Default:Index',
    UserModule_ProfileField_actionAdmin = 'User:ProfileField:Admin',
    UserModule_ProfileField_actionCreate = 'User:ProfileField:Create',
    UserModule_ProfileField_actionDelete = 'User:ProfileField:Delete',
    UserModule_ProfileField_actionUpdate = 'User:ProfileField:Update',
    UserModule_ProfileField_actionView = 'User:ProfileField:View',
    UserModule_Profile_actionChangepassword = 'User:Profile:Changepassword',
    UserModule_Profile_actionEdit = 'User:Profile:Edit',
    UserModule_Profile_actionProfile = 'User:Profile:Profile',
    UserModule_Registration_actionCaptcha = 'User:Registration:Captcha',
    UserModule_User_actionIndex = 'User:User:Index',
    UserModule_User_actionView = 'User:User:View',
    Xtag_actionCreate = 'Xtag:create',
    Xtag_actionDelete = 'Xtag:delete',
    Xtag_actionEditable = 'Xtag:editable',
    Xtag_actionIndex = 'Xtag:index',
    Xtag_actionToggle = 'Xtag:Toggle',
    Xtag_actionUpdate = 'Xtag:update',
    Xtag_actionView = 'Xtag:view',
    Ylog_actionAudittrail = 'Ylog:Audittrail',
    Ylog_actionYlog = 'Ylog:Ylog',
    Ylog_actionCreate = 'Ylog:Create',
    Ylog_actionDelete = 'Ylog:Delete',
    Ylog_actionUpdate = 'Ylog:Update',
    Ylog_actionView = 'Ylog:View',
}
