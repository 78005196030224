import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    private toastArray: Array<HTMLIonToastElement> = []

    constructor(
        private toastCtrl: ToastController,
        private translateSvc: TranslateService
    ) {
    }

    async presentToast(message: string, type: string, duration: number = 2000, icon: string = null): Promise<any> {
        const toast = await this.toastCtrl.create({
            message: this.translateSvc.instant(message),
            cssClass: type,
            duration,
            icon
        });
        if (this.toastArray.findIndex(toast => toast.message === message) < 0) {
            await toast.present();
            this.toastArray.push(toast)
        }
        toast.onDidDismiss().then(()=>{
            this.toastArray.splice(this.toastArray.findIndex(toast => toast.message === message), 1)
        })
    }
}
