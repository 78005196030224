import {Component} from '@angular/core';
import {Validators, FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {MenuController, ModalController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
import {ForgotPasswordModalComponent} from '../forgot-password-modal/forgot-password-modal.component';
import {UserService} from '../../services/user.service';
import {StorageService} from '../../services/storage.service';
import {Browser} from '@capacitor/browser';
import {JWT_KEY} from '../../constants';
import {ToastService} from '../../services/toast.service';
import * as Sentry from "@sentry/capacitor";

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {
    passwordFormModalType = 'sheet';

    loginForm: FormGroup;
    loginAttempt: number = 0;
    showEmailErrors: boolean = false
    validation_messages = {
        email: [
            {type: 'required', message: 'form.input.email.empty_error'},
            {type: 'email', message: 'form.input.email.format_error'}
        ],
        password: [
            {type: 'required', message: 'form.input.password.empty_error'},
            {type: 'minlength', message: 'form.input.password.length_error'}
        ]
    };

    constructor(
        public router: Router,
        public menu: MenuController,
        private apiSvc: ApiService,
        private modalCtrl: ModalController,
        private userSvc: UserService,
        private storageSvc: StorageService,
        private toastSvc: ToastService
    ) {
        if (window.innerWidth > 767) {
            this.passwordFormModalType = 'modal';
        }
        this.loginForm = new FormGroup({
            email: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    Validators.email
                ])
            ),
            password: new FormControl(
                '',
                Validators.compose([Validators.required])
            )
        });
    }

    async doLogin(): Promise<void> {
        this.loginAttempt += 1;
        try {
            const signInResp = await this.apiSvc.signIn(this.loginForm.value.email, this.loginForm.value.password);
            if (signInResp && signInResp.success) {
                await this.storageSvc.setItem(JWT_KEY, JSON.stringify(signInResp));
                this.userSvc.setToken(signInResp.token);
                this.userSvc.setUser(signInResp);
                await this.close(signInResp);

            } else {
                this.loginForm.controls.email.setErrors({'incorrect': true});
                this.loginForm.controls.password.setErrors({'incorrect': true});
                await this.toastSvc.presentToast(signInResp.msg, 'error', 3000, 'close-circle-outline');
            }
        } catch (e) {
            Sentry.withScope((scope) => {
                scope.setTag("userEmail", this.loginForm.value.email)
                Sentry.setExtra('Error Message', e)
                Sentry.captureException('LOGIN ERR: ' + JSON.stringify(e));
            })
            console.log('login error: ', e);
        }
    }

    public async handleForgotPasswordClick(): Promise<void> {
        if (this.passwordFormModalType === 'modal') {
            await this.presentPasswordModal();
        } else {
            await this.presentPasswordSheetModal();
        }
    }

    public async presentPasswordModal(): Promise<any> {
        const modal = await this.modalCtrl.create({
            component: ForgotPasswordModalComponent,
            cssClass: 'password-sheet-modal'
        });
        await modal.present();

    }

    public async presentPasswordSheetModal(): Promise<any> {
        let breakPoint = 0.9;
        if (window.innerHeight > 620) {
            breakPoint = .85
        }
        if (window.innerHeight > 780) {
            breakPoint = .7
        }
        if (window.innerHeight > 860) {
            breakPoint = .65
        }
        const modal = await this.modalCtrl.create({
            component: ForgotPasswordModalComponent,
            cssClass: 'password-sheet-modal',
            initialBreakpoint: breakPoint,
            breakpoints: [0, breakPoint]
        });
        await modal.present();
    }


    async checkEmailErrors() {
        this.showEmailErrors = this.loginForm.controls.email.invalid;
    }

    async openUrl(url: string) {
        await Browser.open({url});
    }

    async close(data = null) {
        await this.modalCtrl.dismiss(data);
    }
}
