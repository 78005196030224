import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MenuController, Platform} from '@ionic/angular';
import {ApiService} from './services/api.service';
import {Router} from '@angular/router';
import {UserService} from './services/user.service';
import {MyRbacOperations} from './enums/user_permissions.enum';
import {SplashScreen} from '@capacitor/splash-screen';
import {LoggedUserDataModel} from './models/logged_user.model';
import {Subscription} from 'rxjs';
import {EventsService} from './services/events.service';
import {TranslationsService} from './services/translations.service';
import {StatusBar, Style} from '@capacitor/status-bar';
import {App} from '@capacitor/app';
import {StorageService} from "./services/storage.service";
import {HelperService} from "./services/helper.service";
import {OrderService} from "./services/order.service";
import * as Sentry from '@sentry/capacitor';
import packageInfo from '../../package.json';
import {MarketableLunchModel} from "./models/marketable_lunch.model";
import {MarketLunchModel} from "./models/market_lunch.model";
import {LunchMarketService} from "./services/lunch-market.service";
import {Browser} from "@capacitor/browser";
import {AnalyticsService} from "./services/analytics.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: [
        './side-menu/styles/side-menu.scss',
        './side-menu/styles/side-menu.shell.scss',
        './side-menu/styles/side-menu.responsive.scss'
    ]
})
export class AppComponent implements OnInit {
    readonly userPermissions = MyRbacOperations;
    public appLngList = [
        {code: 'cs', title: 'Česky'},
        {code: 'sk', title: 'Slovensky'},
        {code: 'en', title: 'English'}
    ];

    public accountType: any = '';
    public userData: any = '';
    public mobile = false;
    public hide = false;
    public loaded: boolean = false;
    public appVersion: string;
    private loginSubscription: Subscription;
    private userUpdateSubscription: Subscription;

    constructor(
        public translate: TranslateService,
        public translateSvc: TranslationsService,
        public platform: Platform,
        private apiSvc: ApiService,
        private router: Router,
        public userSvc: UserService,
        public menuCtrl: MenuController,
        private eventsSvc: EventsService,
        private storageSvc: StorageService,
        public helperSvc: HelperService,
        private orderSvc: OrderService,
        private lunchMarketSvc: LunchMarketService,
        private analyticsSvc: AnalyticsService
    ) {
        this.platform.ready().then(() => {
            this.helperSvc.exitAppOnDoubleTap();
        });
        this.loginSubscription = this.eventsSvc.subscribe('sign-in', async (data) => {
            this.analyticsSvc.setUser()
            await this.helperSvc.initUserAndProviders();
            try {
                await this.lunchMarketSvc.isLunchMarketAvailable();
            } catch (e) {
                console.warn("getUserLunchMarketData ERR", e)
            }
        });

        this.userUpdateSubscription = this.eventsSvc.subscribe('user-updated', async (data) => {
            await this.helperSvc.initUserAndProviders();
        });

        this.initializeApp();
        this.translateSvc.init();
         this.analyticsSvc.initFb();

    }


    async ngOnInit() {
        if (window.innerWidth < 992) {
            this.mobile = true;
        }
        if (this.platform.is('capacitor')) {
            this.appVersion = (await App.getInfo()).version;
        } else {
            this.appVersion = packageInfo.version
        }
    }

    async initializeApp() {
        try {
            if (await this.userSvc.isLogged()) {
                // Reload User data on startup
                const userData: LoggedUserDataModel = await this.apiSvc.getUserData(this.userSvc.getUser().user.id.toString());
                if (userData) {
                    const user = this.userSvc.getUser();
                    user.permissions = userData.permissions;
                    user.accountType = userData.accountType;
                    for (const key in user.user) {
                        if (user.user.hasOwnProperty(key)) {
                            user.user[key] = userData[key];
                        }
                    }
                    this.userSvc.setUser(user);
                    await this.storageSvc.removeItem('userOrder')
                }
                await this.helperSvc.initUserAndProviders();
                try {
                    await this.lunchMarketSvc.isLunchMarketAvailable();
                } catch (e) {
                    console.warn("getUserLunchMarketData ERR", e)
                }
            }

            if (await this.platform.ready()) {
                this.loaded = true;
                await this.staleAStatusBarAndHideSplash()
            }
        } catch (err) {
            Sentry.captureException('INITIALIZE APP: ' + JSON.stringify(err));
            await this.logout();
            this.loaded = true;
            await this.staleAStatusBarAndHideSplash()
        }
    }

    private async staleAStatusBarAndHideSplash() {
        if (this.platform.is('capacitor')) {
            await StatusBar.setStyle({style: Style.Light});
        }
        setTimeout(async () => {
            await SplashScreen.hide({
                fadeOutDuration: 500
            });
        }, 2000);
    }


    async navTo(page: string) {
        await this.menuCtrl.close();
        await this.router.navigate([page], {replaceUrl: true});
        // this.helperSvc.isMenuOpen = false;
        // this.helperSvc.orderComplete = true;
        // this.helperSvc.orderNewMeals = 0

    }

    async openBrowser(url: string) {
        await Browser.open({url});

    }

    async logout() {
        await this.menuCtrl.close();
        await this.apiSvc.logout();
        await this.orderSvc.clearOrder()
        await this.lunchMarketSvc.clear()
        this.helperSvc.orderComplete = true;
        this.helperSvc.orderNewMeals.next(0);
        await this.router.navigate(['home'], {replaceUrl: true});
    }

    async onLngChange(lngCode: string): Promise<void> {
        this.translateSvc.activeLngSubject.next(lngCode);
        this.helperSvc.orderComplete = true;
        this.helperSvc.orderNewMeals.next(0);
        await this.helperSvc.initUserAndProviders();
        if (this.router.url === '/lunch-market') {
            try {
                await this.lunchMarketSvc.isLunchMarketAvailable();
            } catch (e) {
                console.warn("getUserLunchMarketData on lng change ERR", e)
            }
        }
        this.eventsSvc.publish('onMenuClose');
        await this.menuCtrl.close();
    }

}
