import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ProviderOfferService {
  private lunchEditData = new BehaviorSubject<any>("");
  currentLunchEditData = this.lunchEditData.asObservable();
  constructor() {}

  changeLunchEditData(data: any) {
    this.lunchEditData.next(data);
  }
}
