import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { JWT_KEY } from '../constants';

@Injectable()
export class StorageService {

    constructor() {
    }

    setItem(key, value): Promise<any> {
        return Preferences.set({ key, value }); // you can use .then here to check saved value.

    }

    async getItem(key): Promise<any> {
        const { value } = await Preferences.get({ key });
        return value;
    }

    async removeItem(key): Promise<any> {
        await Preferences.remove({ key });
    }
    async clear(): Promise<any> {
        await Preferences.clear();
    }
}
