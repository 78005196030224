import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ProviderClientsService {
  private clientData = new BehaviorSubject<any>("");
  currentClientData = this.clientData.asObservable();
  constructor() {}

  changeClientData(data: any) {
    this.clientData.next(data);
  }
}
